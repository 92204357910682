/**
 * @generated SignedSource<<3dc2bd43ceca2f530008440238faa62e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ExamSessionStatus = "FAILED" | "GRADE_PENDING" | "IN_PROGRESS" | "PASSED" | "PAUSED" | "%future added value";
export type SubmitExamQuizInput = {
  examQuizId: string;
  examSessionId: string;
  startedAt: string;
  submission: Record<string, any>;
};
export type ExamQuizSubmitExamQuizMutation$variables = {
  input: SubmitExamQuizInput;
};
export type ExamQuizSubmitExamQuizMutation$data = {
  readonly submitExamQuiz: {
    readonly examSession: {
      readonly currentExamQuizIndex: number | null | undefined;
      readonly id: string;
      readonly secondsSpent: number;
      readonly status: ExamSessionStatus;
    } | null | undefined;
    readonly quizSubmission: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ExamQuiz_submission">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type ExamQuizSubmitExamQuizMutation = {
  response: ExamQuizSubmitExamQuizMutation$data;
  variables: ExamQuizSubmitExamQuizMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondsSpent",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamSession",
  "kind": "LinkedField",
  "name": "examSession",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentExamQuizIndex",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamQuizSubmitExamQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitExamQuizPayload",
        "kind": "LinkedField",
        "name": "submitExamQuiz",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuizSubmission",
            "kind": "LinkedField",
            "name": "quizSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExamQuiz_submission"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamQuizSubmitExamQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitExamQuizPayload",
        "kind": "LinkedField",
        "name": "submitExamQuiz",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuizSubmission",
            "kind": "LinkedField",
            "name": "quizSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selectedChoiceIndex",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeResponseAnswer",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a53f62e73e93f855041adb14af2182bb",
    "id": null,
    "metadata": {},
    "name": "ExamQuizSubmitExamQuizMutation",
    "operationKind": "mutation",
    "text": "mutation ExamQuizSubmitExamQuizMutation(\n  $input: SubmitExamQuizInput!\n) {\n  submitExamQuiz(input: $input) {\n    examSession {\n      id\n      secondsSpent\n      status\n      currentExamQuizIndex\n    }\n    quizSubmission {\n      id\n      ...ExamQuiz_submission\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment ExamQuiz_submission on StudentQuizSubmission {\n  id\n  selectedChoiceIndex\n  freeResponseAnswer\n  secondsSpent\n  startedAt\n  submittedAt\n  status\n}\n"
  }
};
})();

(node as any).hash = "331cda96973f3fd699f80b2b1250aab9";

export default node;
