"use client";

import {
  graphql,
  PreloadedQuery,
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";

import { Button } from "@/components/ui/button";
import { AttachmentDownloadButton_attachment$key } from "@generated/AttachmentDownloadButton_attachment.graphql";
import { DownloadIcon, FileIcon } from "@radix-ui/react-icons";
import { formatFileSize } from "@/lib/utils";
import { AttachmentDownloadButtonDownloadAttachmentMutation } from "@generated/AttachmentDownloadButtonDownloadAttachmentMutation.graphql";
import { useToast } from "@/components/ui/use-toast";
import { AttachmentDownloadButtonWatermarkJobQuery } from "@generated/AttachmentDownloadButtonWatermarkJobQuery.graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useInterval } from "@/lib/hooks/useInterval";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface Props {
  attachment: AttachmentDownloadButton_attachment$key;
  itemId: string;
}

const AttachmentDownloadButtonWatermarkJobQueryNode = graphql`
  query AttachmentDownloadButtonWatermarkJobQuery($id: ID!) {
    node(id: $id) {
      ... on AttachmentWatermarkJob {
        id
        url
        status
      }
    }
  }
`;

const AttachmentDownloadButton: React.FC<Props> = ({ attachment, itemId }) => {
  const [downloadAttachment, isInFlight] =
    useMutation<AttachmentDownloadButtonDownloadAttachmentMutation>(graphql`
      mutation AttachmentDownloadButtonDownloadAttachmentMutation(
        $input: GetAttachmentUrlInput!
      ) {
        getAttachmentUrl(input: $input) {
          url
          watermarkJob {
            id
            status
            url
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const { toast } = useToast();

  const data = useFragment(
    graphql`
      fragment AttachmentDownloadButton_attachment on Attachment {
        id
        title
        fileSize
      }
    `,
    attachment,
  );

  const [queryReference, loadQuery] =
    useQueryLoader<AttachmentDownloadButtonWatermarkJobQuery>(
      AttachmentDownloadButtonWatermarkJobQueryNode,
    );

  const [watermarkJobId, setWatermarkJobId] = useState<string | null>(null);

  const refetchQuery = useCallback(() => {
    if (watermarkJobId) {
      loadQuery({ id: watermarkJobId }, { fetchPolicy: "network-only" });
    }
  }, [watermarkJobId, loadQuery]);

  return (
    <Button
      className="flex w-full items-center space-x-4 py-4"
      variant="outline"
      role="listitem"
      size="none"
      loading={isInFlight}
      onClick={() => {
        if (isInFlight) return;

        downloadAttachment({
          variables: {
            input: {
              attachmentId: data.id,
              itemId,
            },
          },
          onCompleted: (mutationData) => {
            if (mutationData.getAttachmentUrl?.url) {
              window.open(mutationData.getAttachmentUrl.url, "_blank");
            } else if (mutationData.getAttachmentUrl.watermarkJob?.url) {
              window.open(
                mutationData.getAttachmentUrl.watermarkJob.url,
                "_blank",
              );
            } else if (mutationData.getAttachmentUrl.watermarkJob?.id) {
              loadQuery(
                { id: mutationData.getAttachmentUrl.watermarkJob.id },
                { fetchPolicy: "network-only" },
              );

              setWatermarkJobId(mutationData.getAttachmentUrl.watermarkJob.id);

              toast({
                title: "Dosya hazırlanıyor",
                description: "Birkaç saniye içinde indirme başlayacak.",
              });
            }
          },
        });
      }}
    >
      <div className="rounded bg-primary p-2">
        <FileIcon className="size-4 text-background sm:size-6" />
      </div>
      <div className="grow overflow-hidden text-start">
        <div className="truncate text-base font-normal text-foreground sm:text-xl">
          {data.title}
        </div>
        <div className="font-light text-foreground/60 sm:text-lg">
          {formatFileSize(data.fileSize)}
        </div>
      </div>
      <div className="rounded p-2">
        <DownloadIcon className="size-6 text-foreground/60" />
      </div>
      {queryReference && (
        <DownloadWatermarkedAttachmentDialog
          queryReference={queryReference}
          refetchQuery={refetchQuery}
        />
      )}
    </Button>
  );
};

const DownloadWatermarkedAttachmentDialog: React.FC<{
  queryReference: PreloadedQuery<AttachmentDownloadButtonWatermarkJobQuery>;
  refetchQuery: () => void;
}> = ({ queryReference, refetchQuery }) => {
  const data = usePreloadedQuery(
    AttachmentDownloadButtonWatermarkJobQueryNode,
    queryReference,
  );

  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const hasUrl = Boolean(data.node?.url);

  useEffect(() => {
    setDownloadDialogOpen(hasUrl);
  }, [hasUrl]);

  useInterval(() => {
    if (!hasUrl) {
      refetchQuery();
    }
  }, 5000);

  return (
    <AlertDialog open={downloadDialogOpen} onOpenChange={setDownloadDialogOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Dosya Hazır</AlertDialogTitle>
          <AlertDialogDescription>
            İndirmeyi başlatmak için &ldquo;İndir&rdquo; butonuna tıklayın.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Vazgeç</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              window.open(data.node!.url!, "_blank");
              setDownloadDialogOpen(false);
            }}
          >
            İndir
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AttachmentDownloadButton;
