import { graphql, useFragment } from "react-relay";
import { CourseItemVideoPlayer_video$key } from "@generated/CourseItemVideoPlayer_video.graphql";
import { CourseItemVideoPlayer_progress$key } from "@generated/CourseItemVideoPlayer_progress.graphql";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
const VideoPlayer = dynamic(() => import("@/components/video-player"), {});

const CourseItemVideoPlayer: React.FC<{
  title: string;
  itemId: string;
  video: CourseItemVideoPlayer_video$key;
  progress?: CourseItemVideoPlayer_progress$key;
  mode?: "learn" | "store";
}> = ({ title, itemId, video, progress, mode }) => {
  const videoData = useFragment(
    graphql`
      fragment CourseItemVideoPlayer_video on Video {
        id
        duration
        url
        source
        externalUrl
        chaptersVttUrl
        posterUrl
        thumbnailsUrl
        inVideoQuizzes {
          id
          allowSkip
          showAtProgress
          quiz {
            id
            title
            question
            ... on MultipleChoiceQuiz {
              options {
                content
                description
                isCorrect
              }
            }
            ... on FreeResponseQuiz {
              sampleAnswer
            }
          }
        }
      }
    `,
    video,
  );

  const progressData = useFragment(
    graphql`
      fragment CourseItemVideoPlayer_progress on CourseItem {
        viewerProgress {
          progress
        }
      }
    `,
    progress,
  );

  const initialProgress = progressData?.viewerProgress?.progress;

  const t = useSearchParams()?.get("t");

  return (
    <div className="relative">
      <VideoPlayer
        src={
          videoData.source === "PI"
            ? (videoData.url ?? "")
            : (videoData.externalUrl ?? "")
        }
        title={title}
        thumbnail={
          videoData.source === "PI"
            ? { url: videoData.posterUrl ?? "" }
            : undefined
        }
        thumbnails={
          videoData.source === "PI"
            ? (videoData.thumbnailsUrl ?? undefined)
            : undefined
        }
        initialProgress={
          !initialProgress || initialProgress > 0.99 ? 0 : initialProgress
        }
        chapters={videoData.chaptersVttUrl ?? undefined}
        itemId={itemId}
        autoPlay
        jumpTo={t === null || t === undefined ? undefined : parseInt(t, 10)}
        mode={mode}
      />
    </div>
  );
};

export default CourseItemVideoPlayer;
