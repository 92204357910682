/**
 * @generated SignedSource<<5ce694815c1d156844fe8075a14b6e66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type UpdateAssignmentSubmissionInput = {
  freeResponseAnswer?: AssignmentSubmissionFreeResponseAnswerInput | null | undefined;
  submissionId: string;
};
export type AssignmentSubmissionFreeResponseAnswerInput = {
  value?: any | null | undefined;
};
export type useUpdateAssignmentSubmissionMutation$variables = {
  input: UpdateAssignmentSubmissionInput;
};
export type useUpdateAssignmentSubmissionMutation$data = {
  readonly updateAssignmentSubmission: {
    readonly assignmentSubmission: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssignmentCourseItem_submission">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useUpdateAssignmentSubmissionMutation = {
  response: useUpdateAssignmentSubmissionMutation$data;
  variables: useUpdateAssignmentSubmissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateAssignmentSubmissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssignmentSubmissionPayload",
        "kind": "LinkedField",
        "name": "updateAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "assignmentSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubmittedAssignmentCourseItem_submission"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateAssignmentSubmissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssignmentSubmissionPayload",
        "kind": "LinkedField",
        "name": "updateAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "assignmentSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeResponseAnswer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grade",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gradeFeedback",
                "storageKey": null
              },
              {
                "alias": "assignmentStatus",
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "360e9f6a41cf25d06eebe5e5c197b395",
    "id": null,
    "metadata": {},
    "name": "useUpdateAssignmentSubmissionMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateAssignmentSubmissionMutation(\n  $input: UpdateAssignmentSubmissionInput!\n) {\n  updateAssignmentSubmission(input: $input) {\n    assignmentSubmission {\n      id\n      ...SubmittedAssignmentCourseItem_submission\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment AssignmentCourseItemAttachment_attachment on Attachment {\n  id\n  title\n  createdAt\n  fileName\n  fileSize\n  fileType\n  ...DeleteAssignmentCourseItemAttachmentDialogContent_attachment\n  ...UpdateAttachmentDialog_attachment\n}\n\nfragment DeleteAssignmentCourseItemAttachmentDialogContent_attachment on Attachment {\n  id\n  title\n}\n\nfragment SubmittedAssignmentCourseItem_submission on AssignmentSubmission {\n  id\n  freeResponseAnswer\n  grade\n  gradeFeedback\n  assignmentStatus: status\n  attachments {\n    id\n    ...AssignmentCourseItemAttachment_attachment\n  }\n}\n\nfragment UpdateAttachmentDialog_attachment on Attachment {\n  id\n  title\n  fileName\n}\n"
  }
};
})();

(node as any).hash = "0a44e11cde2c1ab9c2c87b1086b2d0da";

export default node;
