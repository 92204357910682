/**
 * @generated SignedSource<<e488e1a2d4801b1622b7c9db93f791e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizSubmissionStatus = "DRAFT" | "GRADED" | "GRADE_PENDING" | "SKIPPED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type SubmittedQuizCourseItem_submission$data = {
  readonly freeResponseAnswer: any | null | undefined;
  readonly grade: number | null | undefined;
  readonly gradeFeedback: any | null | undefined;
  readonly id: string;
  readonly secondsSpent: number;
  readonly selectedChoiceIndex: number | null | undefined;
  readonly startedAt: string;
  readonly status: QuizSubmissionStatus;
  readonly submittedAt: string;
  readonly " $fragmentType": "SubmittedQuizCourseItem_submission";
};
export type SubmittedQuizCourseItem_submission$key = {
  readonly " $data"?: SubmittedQuizCourseItem_submission$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmittedQuizCourseItem_submission">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmittedQuizCourseItem_submission",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedChoiceIndex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeResponseAnswer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gradeFeedback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondsSpent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "StudentQuizSubmission",
  "abstractKey": null
};

(node as any).hash = "a04e77ec677f013d0a51e5a1c2fb1e7a";

export default node;
