"use client";

import { Button, buttonVariants } from "@/components/ui/button";
import { graphql, useFragment } from "react-relay";
import { cn } from "@/lib/utils";
import dynamic from "next/dynamic";
import { SubmittedQuizCourseItem_submission$key } from "@generated/SubmittedQuizCourseItem_submission.graphql";
import { SubmittedQuizCourseItem$key } from "@generated/SubmittedQuizCourseItem.graphql";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  InfoCircledIcon,
  LapTimerIcon,
  Pencil2Icon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";
import React, { useState } from "react";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import CourseItemAttachmentsSection from "@/courses/components/CourseItemAttachmentsSection";
import CourseItemVideoPlayer from "@/courses/components/CourseItemVideoPlayer";
import CourseItemDescription from "@/courses/components/CourseItemDescription";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const SubmittedQuizCourseItem: React.FC<{
  item: SubmittedQuizCourseItem$key;
  submission: SubmittedQuizCourseItem_submission$key;
  onRetake: () => void;
  mode: "store" | "learn";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({
  item,
  submission,
  onRetake,
  mode: learningMode,
  courseContentsSide,
}) => {
  const data = useFragment(
    graphql`
      fragment SubmittedQuizCourseItem on QuizCourseItem {
        id
        title
        description
        quiz @required(action: THROW) {
          id
          question
          title
          __typename
          ... on MultipleChoiceQuiz {
            options {
              content
              description
              isCorrect
            }
          }
          ... on FreeResponseQuiz {
            sampleAnswer
          }
          solutionVideo {
            ...CourseItemVideoPlayer_video
          }
          solutionVideoCookiesUrl
        }
        allowRetake
        ...CourseItemAttachmentsSection_attachments
      }
    `,
    item,
  );

  const submissionData = useFragment(
    graphql`
      fragment SubmittedQuizCourseItem_submission on StudentQuizSubmission {
        id
        selectedChoiceIndex
        freeResponseAnswer
        grade
        gradeFeedback
        secondsSpent
        startedAt
        submittedAt
        status
      }
    `,
    submission,
  );

  const [mode, setMode] = useState<"question" | "solution">("question");

  const submissionGrade = (submissionData.grade ?? 0) * 100;

  const submittedOption =
    submissionData.selectedChoiceIndex !== null &&
    submissionData.selectedChoiceIndex !== undefined
      ? data.quiz.options?.[submissionData.selectedChoiceIndex]
      : undefined;
  const correctOption = data.quiz.options?.find((option) => option.isCorrect);

  return (
    <>
      <section className="mt-2 px-2 sm:mt-4 sm:px-4">
        <div className="mb-2 flex flex-wrap items-center gap-4">
          <h3 className="text-xl font-semibold">
            {data.title} (
            {submissionData?.status === "GRADE_PENDING"
              ? "?"
              : submissionGrade.toLocaleString("en", {
                  useGrouping: false,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
            /100 Puan)
            {submissionData?.status === "GRADE_PENDING"
              ? " - Notun henüz hesaplanmadı."
              : ""}
          </h3>
          {data.allowRetake && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button size="sm" variant="outline">
                  Tekrar Çöz
                  <LapTimerIcon className="ml-1.5 size-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Soruyu tekrar çözmek istediğine emin misiniz?
                  </AlertDialogTitle>
                  <AlertDialogDescription></AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Vazgeç</AlertDialogCancel>
                  <AlertDialogAction onClick={onRetake}>
                    Soruyu Tekrar Çöz
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
        {mode === "question" ? (
          <>
            <PlateEditor value={data.quiz.question} readOnly dense />
            <div className="grid grid-cols-2 gap-4 pb-4 sm:grid-cols-4">
              {data.quiz.__typename === "FreeResponseQuiz" ? (
                <div className="col-span-2 sm:col-span-4">
                  <h3 className="font-semibold">Cevabın</h3>
                  {submissionData?.freeResponseAnswer ? (
                    <PlateEditor
                      value={submissionData?.freeResponseAnswer}
                      readOnly
                      dense
                    />
                  ) : (
                    <p className="text-sm">Bu soruyu boş bıraktın.</p>
                  )}

                  {data.quiz.sampleAnswer ? (
                    <>
                      <h3 className="mt-4 font-semibold">Örnek Cevap</h3>
                      <PlateEditor
                        value={data.quiz.sampleAnswer}
                        readOnly
                        dense
                      />
                    </>
                  ) : null}
                </div>
              ) : (
                data.quiz.options?.map((option, index) => (
                  <div
                    key={index}
                    className={cn(
                      buttonVariants({
                        variant: "outline",
                        size: "none",
                      }),
                      "col-span-2 sm:last:odd:col-start-2",
                      {
                        "bg-red-200 hover:bg-red-200":
                          submissionData?.selectedChoiceIndex === index,
                        "bg-green-200 hover:bg-green-200 dark:bg-green-800 dark:hover:bg-green-800":
                          option.isCorrect,
                      },
                    )}
                  >
                    <PlateEditor
                      value={option.content}
                      readOnly
                      className="bg-transparent"
                      dense
                    />
                    {option.description ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <InfoCircledIcon />
                          </TooltipTrigger>
                          <TooltipContent variant="background" className="p-0">
                            <PlateEditor
                              value={option.description}
                              readOnly
                              containerClassName={cn("flex items-center")}
                            />
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : null}
                  </div>
                ))
              )}
            </div>
            {(submittedOption && submittedOption.description) ||
            (correctOption && correctOption.description) ? (
              <Alert
                variant={
                  submittedOption && submittedOption.isCorrect
                    ? "success"
                    : "info"
                }
              >
                <AlertTitle className="font-bold">
                  {submittedOption && submittedOption.isCorrect
                    ? "Tebrikler! Doğru cevap."
                    : "Maalesef soruyu doğru cevaplayamadın. Aşağıda cevabın açıklamasını bulabilirsin."}
                </AlertTitle>
                <PlateEditor
                  value={
                    submittedOption?.description ?? correctOption?.description
                  }
                  readOnly
                  containerClassName={cn("flex items-center")}
                />
              </Alert>
            ) : null}
          </>
        ) : (
          <CourseItemVideoPlayer
            title={data.title}
            video={data.quiz.solutionVideo!}
            itemId={data.id}
            mode={learningMode}
          />
        )}
      </section>
      <div
        className={cn("flex items-center gap-x-2 px-4 pt-4", {
          "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
        })}
      >
        <h2 className="flex-1 truncate text-lg font-bold text-foreground">
          {data.title}
        </h2>
        {data.quiz.solutionVideo ? (
          mode === "question" ? (
            <Button variant="outline" onClick={() => setMode("solution")}>
              <VideoIcon className="mr-2 size-4" />
              Çözümü İzle
            </Button>
          ) : (
            <Button variant="outline" onClick={() => setMode("question")}>
              <Pencil2Icon className="mr-2 size-4" />
              Soruya Dön
            </Button>
          )
        ) : null}
        {learningMode === "learn" && <NextItemButton />}
      </div>
      {learningMode === "learn" && (
        <div
          className={cn("flex items-center gap-x-2 px-4 pt-2", {
            "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <ReportItemButton />
        </div>
      )}
      <CourseItemDescription
        description={data.description}
        courseContentsSide={courseContentsSide}
      />
      <CourseItemAttachmentsSection
        attachments={data}
        courseContentsSide={courseContentsSide}
      />
      {submissionGrade > 80 && mode === "question" ? (
        <PassedQuizConfetti />
      ) : null}
    </>
  );
};

const PassedQuizConfetti: React.FC = () => {
  const { width, height } = useWindowSize();

  return <Confetti width={width ?? undefined} height={height ?? undefined} />;
};

export default SubmittedQuizCourseItem;
