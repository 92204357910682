import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { useCreateDraftAssignmentSubmissionMutation } from "@generated/useCreateDraftAssignmentSubmissionMutation.graphql";

const useCreateDraftAssignmentSubmission = () => {
  const [commitCreateDraftAssignmentSubmission, isInFlight] = useMutation<useCreateDraftAssignmentSubmissionMutation>(
    graphql`
      mutation useCreateDraftAssignmentSubmissionMutation($input: CreateDraftAssignmentSubmissionInput!) {
        createDraftAssignmentSubmission(input: $input) {
          assignmentSubmission {
            id
            ...SubmittedAssignmentCourseItem_submission
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `,
  );

  const createDraftAssignmentSubmission = useCallback(
    (args: UseMutationConfig<useCreateDraftAssignmentSubmissionMutation>) => {
      return commitCreateDraftAssignmentSubmission({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.createDraftAssignmentSubmission.userErrors.length > 0 ||
            !mutationData.createDraftAssignmentSubmission.assignmentSubmission
          ) {
            return;
          }

          const assignmentSubmissionRecord = store.get(
            mutationData.createDraftAssignmentSubmission.assignmentSubmission.id,
          )!;

          const itemId = args.variables.input.itemId;

          if (itemId) {
            const itemRecord = store.get(itemId)!;

            itemRecord.setLinkedRecord(
              assignmentSubmissionRecord,
              "viewerLatestSubmission",
            );

          }
        },
      });
    },
    [commitCreateDraftAssignmentSubmission],
  );

  return [createDraftAssignmentSubmission, isInFlight] as const;
};

export default useCreateDraftAssignmentSubmission;
