/**
 * @generated SignedSource<<94ce2cfb3486d4d0d548d07c3072c053>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SubmittedAssignmentCourseItem$data = {
  readonly allowFileUpload: boolean;
  readonly allowMultipleSubmissions: boolean;
  readonly allowRichText: boolean;
  readonly description: any | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments">;
  readonly " $fragmentType": "SubmittedAssignmentCourseItem";
};
export type SubmittedAssignmentCourseItem$key = {
  readonly " $data"?: SubmittedAssignmentCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssignmentCourseItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmittedAssignmentCourseItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowRichText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowFileUpload",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowMultipleSubmissions",
      "storageKey": null
    }
  ],
  "type": "AssignmentCourseItem",
  "abstractKey": null
};

(node as any).hash = "55017465b76b3cd608599228997f4449";

export default node;
