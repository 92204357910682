"use client";

import { graphql, useFragment } from "react-relay";
import { VideoCourseItem$key } from "@generated/VideoCourseItem.graphql";
import CourseItemVideoPlayer from "@/courses/components/CourseItemVideoPlayer";
import CourseItemAttachmentsSection from "@/courses/components/CourseItemAttachmentsSection";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import { WrenchIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import CourseItemDescription from "@/courses/components/CourseItemDescription";

interface Props {
  item: VideoCourseItem$key;
  mode: "learn" | "store";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}

export const VideoCourseItem: React.FC<Props> = ({
  item,
  mode,
  courseContentsSide,
}) => {
  const data = useFragment(
    graphql`
      fragment VideoCourseItem on VideoCourseItem {
        id
        title
        description
        ...CourseItemVideoPlayer_progress
        video {
          ...CourseItemVideoPlayer_video
        }
        ...CourseItemAttachmentsSection_attachments
      }
    `,
    item,
  );

  if (!data.video)
    return (
      <>
        {mode === "store" ? (
          <div className={cn("flex items-center gap-x-2")}>
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
          </div>
        ) : null}
        <div
          className={cn("px-4 pt-4 text-center sm:pt-8", {
            "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <WrenchIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Video henüz yayınlanmadı.
          </h3>
          <p className="mt-1 text-sm text-foreground">
            Lütfen eğitmeninizle iletişime geçin.
          </p>
        </div>
        {mode === "learn" ? (
          <>
            <div
              className={cn("flex items-center gap-x-2 px-4 pt-4", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <h2 className="flex-1 truncate text-lg font-bold text-foreground">
                {data.title}
              </h2>
              <NextItemButton />
            </div>
            <div
              className={cn("flex items-center gap-x-2 px-4 pt-2", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <ReportItemButton />
            </div>
          </>
        ) : null}
        <CourseItemDescription
          description={data.description}
          courseContentsSide={courseContentsSide}
        />
        <CourseItemAttachmentsSection
          attachments={data}
          courseContentsSide={courseContentsSide}
        />
      </>
    );

  return (
    <>
      {mode === "store" ? (
        <div className={cn("flex items-center gap-x-2")}>
          <h2 className="flex-1 truncate text-lg font-bold text-foreground">
            {data.title}
          </h2>
        </div>
      ) : null}
      <CourseItemVideoPlayer
        title={data.title}
        video={data.video}
        progress={data}
        itemId={data.id}
        mode={mode}
      />
      {mode === "learn" ? (
        <>
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            <NextItemButton />
          </div>
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
          </div>
        </>
      ) : null}
      <CourseItemDescription
        description={data.description}
        courseContentsSide={courseContentsSide}
      />
      <CourseItemAttachmentsSection
        attachments={data}
        courseContentsSide={courseContentsSide}
      />
    </>
  );
};

export default VideoCourseItem;
