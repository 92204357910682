import {
  useFinishedExamQuizzesWithSubmissions_examSession$data,
  useFinishedExamQuizzesWithSubmissions_examSession$key,
} from "@generated/useFinishedExamQuizzesWithSubmissions_examSession.graphql";
import { graphql, useFragment } from "react-relay";

const useFinishedExamQuizzesWithSubmissions = ({
  examSession,
}: {
  examSession:
    | useFinishedExamQuizzesWithSubmissions_examSession$key
    | null
    | undefined;
}): Array<{
  examQuiz: useFinishedExamQuizzesWithSubmissions_examSession$data["examQuizzes"][number];
  submission:
    | useFinishedExamQuizzesWithSubmissions_examSession$data["submissions"][number]
    | null;
}> => {
  const submissionsData = useFragment(
    graphql`
      fragment useFinishedExamQuizzesWithSubmissions_examSession on ExamSession {
        examQuizzes {
          id
          points
          ...FinishedExamQuiz
        }
        submissions {
          id
          examQuizId
          grade
          status
          ...FinishedExamQuiz_submission
        }
      }
    `,
    examSession,
  );

  if (submissionsData === null || submissionsData === undefined) {
    return [];
  }

  return submissionsData.examQuizzes.map((examQuiz) => {
    const submission = submissionsData?.submissions.find(
      (submission) => submission.examQuizId === examQuiz.id,
    );

    return {
      examQuiz,
      submission: submission ?? null,
    };
  });
};

export default useFinishedExamQuizzesWithSubmissions;
