/**
 * @generated SignedSource<<58d2d65468c300079daca4c3c9b63d85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type QuizCourseItem$data = {
  readonly description: any | null | undefined;
  readonly id: string;
  readonly quiz: {
    readonly id: string;
  } | null | undefined;
  readonly title: string;
  readonly viewerLatestSubmission: {
    readonly " $fragmentSpreads": FragmentRefs<"SubmittedQuizCourseItem_submission">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AnswerQuizCourseItem" | "CourseItemAttachmentsSection_attachments" | "SubmittedQuizCourseItem">;
  readonly " $fragmentType": "QuizCourseItem";
};
export type QuizCourseItem$key = {
  readonly " $data"?: QuizCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizCourseItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizCourseItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "quiz",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudentQuizSubmission",
      "kind": "LinkedField",
      "name": "viewerLatestSubmission",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SubmittedQuizCourseItem_submission"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmittedQuizCourseItem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnswerQuizCourseItem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    }
  ],
  "type": "QuizCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "ef85558d49241db12efb380f51b09eae";

export default node;
