"use client";
import { graphql, useFragment } from "react-relay";
import React from "react";
import { WrenchIcon } from "lucide-react";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import { AssignmentCourseItem$key } from "@generated/AssignmentCourseItem.graphql";
import StartAssignmentCourseItem from "./StartAssignmentCourseItem";
import SubmittedAssignmentCourseItem from "./SubmittedAssignmentCourseItem";
import { cn } from "@/lib/utils";

export const AssignmentCourseItem: React.FC<{
  item: AssignmentCourseItem$key;
  mode: "learn" | "store";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, mode, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment AssignmentCourseItem on AssignmentCourseItem {
        id
        title
        description
        attachments {
          id
          ...CourseAdminAttachment_attachment
        }
        ...SubmittedAssignmentCourseItem
        ...StartAssignmentCourseItem

        viewerLatestSubmission {
          assignmentStatus: status
          ...SubmittedAssignmentCourseItem_submission
        }
      }
    `,
    item,
  );

  if (!data.description) {
    return (
      <>
        {mode === "store" ? (
          <div className={cn("flex items-center gap-x-2")}>
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
          </div>
        ) : null}
        <div
          className={cn("px-4 pt-4 text-center sm:pt-8", {
            "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <WrenchIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Bu ödev henüz yayınlanmadı.
          </h3>
          <p className="mt-1 text-sm text-foreground/60">
            Lütfen eğitmeninizle iletişime geçin.
          </p>
        </div>
        {mode === "learn" && (
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            <ReportItemButton />
            <NextItemButton />
          </div>
        )}
      </>
    );
  }

  if (!data.viewerLatestSubmission) {
    return (
      <StartAssignmentCourseItem
        item={data}
        mode={mode}
        courseContentsSide={courseContentsSide}
      />
    );
  }
  return (
    <SubmittedAssignmentCourseItem
      item={data}
      submission={data.viewerLatestSubmission}
      courseContentsSide={courseContentsSide}
    />
  );
};

export default AssignmentCourseItem;
