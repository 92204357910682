import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { type useStartExamSessionMutation } from "@generated/useStartExamSessionMutation.graphql";

const useStartExamSession = () => {
  const [commitStartExamSession, isInFlight] =
    useMutation<useStartExamSessionMutation>(graphql`
      mutation useStartExamSessionMutation($input: StartExamSessionInput!) {
        startExamSession(input: $input) {
          examSession {
            id
            status
            startedAt
            currentExamQuizIndex
            id
            item {
              id
              viewerCanStartSession
            }
            ...useExamQuizzesWithSubmissions_examSession
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const startExamSession = useCallback(
    (args: UseMutationConfig<useStartExamSessionMutation>) => {
      return commitStartExamSession({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.startExamSession.userErrors.length > 0 ||
            !mutationData.startExamSession.examSession
          ) {
            return;
          }

          const newNode = store.get(
            mutationData.startExamSession.examSession.id,
          )!;

          if (args.variables.input.itemId) {
            const itemRecord = store.get(args.variables.input.itemId);

            if (itemRecord) {
              itemRecord.setLinkedRecord(newNode, "viewerCurrentSession");
            }
          }
        },
      });
    },
    [commitStartExamSession],
  );

  return [startExamSession, isInFlight] as const;
};

export default useStartExamSession;
