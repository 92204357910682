"use client";

import { CourseItemAttachmentsSection_attachments$key } from "@generated/CourseItemAttachmentsSection_attachments.graphql";
import { graphql, useFragment } from "react-relay";
import AttachmentDownloadButton from "@/courses/components/AttachmentDownloadButton";
import { cn } from "@/lib/utils";

const CourseItemAttachmentsSection: React.FC<{
  attachments: CourseItemAttachmentsSection_attachments$key;
  className?: string;
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ attachments, className, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment CourseItemAttachmentsSection_attachments on CourseItem {
        id
        attachments {
          id
          ...AttachmentDownloadButton_attachment
        }
      }
    `,
    attachments,
  );

  if (!data.attachments.length) return null;

  return (
    <div
      className={cn(
        "px-4 pt-4",
        {
          "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
        },
        className,
      )}
    >
      <h2 className="text-lg font-bold">Ekler</h2>
      <ol className="mt-2 grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
        {data.attachments.map((attachment) => (
          <AttachmentDownloadButton
            key={attachment.id}
            itemId={data.id}
            attachment={attachment}
          />
        ))}
      </ol>
    </div>
  );
};

export default CourseItemAttachmentsSection;
