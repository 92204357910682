"use client";

import { graphql, useFragment, useMutation } from "react-relay";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import React from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { DeleteAssignmentCourseItemAttachmentDialogContentDeleteAssignmentSubmissionAttachmentMutation } from "@generated/DeleteAssignmentCourseItemAttachmentDialogContentDeleteAssignmentSubmissionAttachmentMutation.graphql";
import { DeleteAssignmentCourseItemAttachmentDialogContent_attachment$key } from "@generated/DeleteAssignmentCourseItemAttachmentDialogContent_attachment.graphql";

const DeleteAssignmentCourseItemAttachmentDialogContent: React.FC<{
  submissionId: string;
  attachment: DeleteAssignmentCourseItemAttachmentDialogContent_attachment$key;
  onAttachmentDeleted: () => void;
}> = ({ submissionId, attachment, onAttachmentDeleted }) => {
  const data = useFragment(
    graphql`
      fragment DeleteAssignmentCourseItemAttachmentDialogContent_attachment on Attachment {
        id
        title
      }
    `,
    attachment,
  );

  const { toast } = useToast();

  const [
    commitDeleteAssignmentSubmissionAttachment,
    isDeleteAssignmentSubmissionAttachment,
  ] =
    useMutation<DeleteAssignmentCourseItemAttachmentDialogContentDeleteAssignmentSubmissionAttachmentMutation>(
      graphql`
        mutation DeleteAssignmentCourseItemAttachmentDialogContentDeleteAssignmentSubmissionAttachmentMutation(
          $input: DeleteAssignmentSubmissionAttachmentInput!
        ) {
          deleteAssignmentSubmissionAttachment(input: $input) {
            submissionId
            deletedAttachmentId
            userErrors {
              code
              field
              message
            }
          }
        }
      `,
    );

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          &ldquo;{data.title}&rdquo; Ek Dosyasını Silmek İstediğine Emin Misin?
        </DialogTitle>
        <DialogDescription>Bu işlem geri alınamaz.</DialogDescription>
      </DialogHeader>

      <DialogFooter>
        <Button
          className="w-full"
          variant="destructive"
          loading={isDeleteAssignmentSubmissionAttachment}
          onClick={() => {
            if (isDeleteAssignmentSubmissionAttachment) {
              return;
            }

            commitDeleteAssignmentSubmissionAttachment({
              variables: {
                input: {
                  submissionId: submissionId,
                  attachmentId: data.id,
                },
              },
              updater: (store, mutationData) => {
                if (
                  mutationData === null ||
                  mutationData === undefined ||
                  !mutationData.deleteAssignmentSubmissionAttachment
                    .submissionId ||
                  !mutationData.deleteAssignmentSubmissionAttachment
                    .deletedAttachmentId
                ) {
                  return;
                }

                const courseItemRecord = store.get(
                  mutationData.deleteAssignmentSubmissionAttachment
                    .submissionId,
                );

                if (!courseItemRecord) {
                  return;
                }

                const attachments =
                  courseItemRecord.getLinkedRecords("attachments")!;

                const newAttachments = attachments.filter(
                  (attachment) =>
                    attachment &&
                    attachment.getDataID() !==
                      mutationData.deleteAssignmentSubmissionAttachment
                        .deletedAttachmentId,
                );

                courseItemRecord.setLinkedRecords(
                  newAttachments,
                  "attachments",
                );
              },
              onCompleted: (data) => {
                if (
                  data.deleteAssignmentSubmissionAttachment.userErrors.length >
                  0
                ) {
                  toast({
                    title: "İçerik eki silinemedi.",
                    description: `Lütfen tekrar dene. Hata mesajı: ${
                      data.deleteAssignmentSubmissionAttachment.userErrors[0]!
                        .message
                    }`,
                  });
                } else {
                  onAttachmentDeleted();
                }
              },
            });
          }}
        >
          İçerik Ekini Sil
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default DeleteAssignmentCourseItemAttachmentDialogContent;
