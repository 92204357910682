import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { useUpdateAssignmentSubmissionMutation } from "@generated/useUpdateAssignmentSubmissionMutation.graphql";

const useUpdateAssignmentSubmission = () => {
  const [commitUpdateAssignmentSubmission, isInFlight] = useMutation<useUpdateAssignmentSubmissionMutation>(
    graphql`
      mutation useUpdateAssignmentSubmissionMutation($input: UpdateAssignmentSubmissionInput!) {
        updateAssignmentSubmission(input: $input) {
          assignmentSubmission {
            id
            ...SubmittedAssignmentCourseItem_submission
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `,
  );

  const updateAssignmentSubmission = useCallback(
    (args: UseMutationConfig<useUpdateAssignmentSubmissionMutation>) => {
      return commitUpdateAssignmentSubmission({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.updateAssignmentSubmission.userErrors.length > 0 ||
            !mutationData.updateAssignmentSubmission.assignmentSubmission
          ) {
            return;
          }

          const assignmentSubmissionRecord = store.get(
            mutationData.updateAssignmentSubmission.assignmentSubmission.id,
          )!;

          const submissionId = args.variables.input.submissionId;

          if (submissionId) {
            const itemRecord = store.get(submissionId)!;

            itemRecord.setLinkedRecord(
              assignmentSubmissionRecord,
              "viewerLatestSubmission",
            );

          }
        },
      });
    },
    [commitUpdateAssignmentSubmission],
  );

  return [updateAssignmentSubmission, isInFlight] as const;
};

export default useUpdateAssignmentSubmission;
