"use client";

import dynamic from "next/dynamic";
import { cn } from "@/lib/utils";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const CourseItemDescription: React.FC<{
  description: any;
  className?: string;
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ description, className, courseContentsSide }) => {
  if (!description) return null;

  return (
    <div
      className={cn(
        "px-4 pt-4",
        {
          "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
        },
        className,
      )}
    >
      <PlateEditor value={description} readOnly dense />
    </div>
  );
};

export default CourseItemDescription;
