/**
 * @generated SignedSource<<e5b506dd55dd9ecd12fb84016e351c8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssignmentCourseItemAttachment_attachment$data = {
  readonly createdAt: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly fileType: string;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteAssignmentCourseItemAttachmentDialogContent_attachment" | "UpdateAttachmentDialog_attachment">;
  readonly " $fragmentType": "AssignmentCourseItemAttachment_attachment";
};
export type AssignmentCourseItemAttachment_attachment$key = {
  readonly " $data"?: AssignmentCourseItemAttachment_attachment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCourseItemAttachment_attachment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentCourseItemAttachment_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteAssignmentCourseItemAttachmentDialogContent_attachment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateAttachmentDialog_attachment"
    }
  ],
  "type": "Attachment",
  "abstractKey": null
};

(node as any).hash = "25ae15529e32051509b4853d481bad5b";

export default node;
