"use client";

import { graphql, useFragment } from "react-relay";
import React from "react";
import { isFuture, parseISO } from "date-fns";
import dynamic from "next/dynamic";
import { LapTimerIcon } from "@radix-ui/react-icons";
import useCreateDraftAssignmentSubmission from "@/courses/assignment/hooks/useCreateDraftAssignmentSubmission";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import { Button } from "@/components/ui/button";
import {
  StartAssignmentCourseItem$data,
  StartAssignmentCourseItem$key,
} from "@generated/StartAssignmentCourseItem.graphql";
import { formatDateToRelative } from "@/lib/date-utils";
import { cn } from "@/lib/utils";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const StartAssignmentCourseItem: React.FC<{
  item: StartAssignmentCourseItem$key;
  mode: "learn" | "store";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, mode, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment StartAssignmentCourseItem on AssignmentCourseItem {
        id
        title
        description
        allowRichText
        allowFileUpload
        allowMultipleSubmissions
        dueDate
        lateSubmissionDeadline
        attachments {
          id
          ...CourseAdminAttachment_attachment
        }
      }
    `,
    item,
  );

  const [commitCreateDraftAssignmentSubmission, isCreateDraftFlight] =
    useCreateDraftAssignmentSubmission();

  if (!data.allowRichText && !data.allowFileUpload) {
    return (
      <div className="pt-4 text-center sm:pt-8">
        <ExclamationTriangleIcon className="mx-auto size-12 text-primary" />
        <h3 className="mt-2 text-sm font-semibold text-foreground">
          Bu içerikte bir hata var.
        </h3>
        <p className="mt-1 text-sm text-foreground/60">
          Lütfen eğitmeninizle iletişime geçin.
        </p>
        {mode === "learn" && (
          <>
            <NextItemButton />
            <div
              className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <ReportItemButton />
            </div>
          </>
        )}
      </div>
    );
  }

  const descriptionText =
    data.description
      ?.map((instruction: { children: any[] }) =>
        instruction.children.map((child: { text: any }) => child.text).join(""),
      )
      .join("\n") ?? "";

  const newDescription =
    descriptionText +
    "\n" +
    ("\n Bu ödevi birden fazla defa çözme hakkınız " +
      (data.allowMultipleSubmissions ? "bulunmaktadır." : "bulunmamaktadır.")) +
    (data.dueDate && isFuture(parseISO(data.dueDate))
      ? "\n Bu ödevin teslim tarihi: " + formatDateToRelative(data.dueDate)
      : data.lateSubmissionDeadline &&
          isFuture(parseISO(data.lateSubmissionDeadline))
        ? "\n 🔴 Bu ödevin geç teslim tarihi: " +
          formatDateToRelative(data.lateSubmissionDeadline)
        : "");

  const plateElements = checkDeadlines(data)
    ? newDescription.split("\n").map((line) => ({
        type: "paragraph",
        children: [{ text: line }],
      }))
    : [
        {
          type: "paragraph",
          children: [{ text: "Ödevin son teslim tarihi geçmiştir." }],
        },
      ];

  return (
    <div>
      <PlateEditor value={plateElements} readOnly dense />
      <Button
        disabled={!checkDeadlines(data)}
        size="lg"
        className="mt-2"
        onClick={() => {
          if (isCreateDraftFlight) {
            return;
          }
          commitCreateDraftAssignmentSubmission({
            variables: {
              input: {
                itemId: data.id,
              },
            },
          });
        }}
      >
        Ödeve Başla
        <LapTimerIcon className="ml-2 size-4" />
      </Button>
    </div>
  );
};

function checkDeadlines(data: StartAssignmentCourseItem$data) {
  if (!data.dueDate && !data.lateSubmissionDeadline) {
    return true;
  }
  if (
    data.lateSubmissionDeadline &&
    isFuture(parseISO(data.lateSubmissionDeadline))
  ) {
    return true;
  }
  if (
    data.dueDate &&
    !data.lateSubmissionDeadline &&
    isFuture(parseISO(data.dueDate))
  ) {
    return true;
  }
  if (
    data.dueDate &&
    data.lateSubmissionDeadline &&
    (isFuture(parseISO(data.dueDate)) ||
      isFuture(parseISO(data.lateSubmissionDeadline)))
  ) {
    return true;
  }
}

export default StartAssignmentCourseItem;
