/**
 * @generated SignedSource<<2ee904883a51bed6ba4098c70e4219ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DeleteAssignmentCourseItemAttachmentDialogContent_attachment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "DeleteAssignmentCourseItemAttachmentDialogContent_attachment";
};
export type DeleteAssignmentCourseItemAttachmentDialogContent_attachment$key = {
  readonly " $data"?: DeleteAssignmentCourseItemAttachmentDialogContent_attachment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteAssignmentCourseItemAttachmentDialogContent_attachment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteAssignmentCourseItemAttachmentDialogContent_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Attachment",
  "abstractKey": null
};

(node as any).hash = "1f1a8d6a58eb96448fcc6d0a64de87f6";

export default node;
