/**
 * @generated SignedSource<<adce12d664ed86c15cffb7911db2251e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentSubmissionStatus = "DRAFT" | "GRADED" | "GRADE_PENDING" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type AssignmentCourseItem$data = {
  readonly attachments: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CourseAdminAttachment_attachment">;
  }>;
  readonly description: any | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly viewerLatestSubmission: {
    readonly assignmentStatus: AssignmentSubmissionStatus;
    readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssignmentCourseItem_submission">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"StartAssignmentCourseItem" | "SubmittedAssignmentCourseItem">;
  readonly " $fragmentType": "AssignmentCourseItem";
};
export type AssignmentCourseItem$key = {
  readonly " $data"?: AssignmentCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCourseItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentCourseItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CourseAdminAttachment_attachment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmittedAssignmentCourseItem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartAssignmentCourseItem"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmission",
      "kind": "LinkedField",
      "name": "viewerLatestSubmission",
      "plural": false,
      "selections": [
        {
          "alias": "assignmentStatus",
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SubmittedAssignmentCourseItem_submission"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssignmentCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "9de848bcb256f4a6120d2079e4ecc6c2";

export default node;
