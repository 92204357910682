"use client";

import { graphql, useFragment } from "react-relay";
import { FinishedExamSessionResults_session$key } from "@generated/FinishedExamSessionResults_session.graphql";
import useFinishedExamQuizzesWithSubmissions from "@/courses/exam/hooks/useFinishedExamQuizzesWithSubmissions";
import { useState } from "react";
import FinishedExamQuiz from "@/courses/exam/components/FinishedExamQuiz";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { format, parseISO } from "date-fns";
import { tr } from "date-fns/locale";
import useStartExamSession from "@/courses/exam/hooks/useStartExamSession";
import { Button } from "@/components/ui/button";
import { LapTimerIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";

const FinishedExamSessionResults: React.FC<{
  itemId: string;
  examId: string;
  session: FinishedExamSessionResults_session$key;
  title: string;
  viewerCanStartSession: boolean;
  passingGradePercentage?: number | null;
}> = ({
  session,
  itemId,
  examId,
  title,
  passingGradePercentage,
  viewerCanStartSession,
}) => {
  const data = useFragment(
    graphql`
      fragment FinishedExamSessionResults_session on ExamSession {
        id
        status
        finishedAt
        points
        maxPoints
        ...useFinishedExamQuizzesWithSubmissions_examSession
      }
    `,
    session,
  );

  const examQuestionsWithSubmissions = useFinishedExamQuizzesWithSubmissions({
    examSession: data,
  });

  const totalPendingQuizGrades = examQuestionsWithSubmissions.reduce(
    (acc, submission) => {
      if (submission.submission?.status === "GRADE_PENDING") {
        return acc + submission.examQuiz.points;
      }
      return acc;
    },
    0,
  );

  const [startExamSession, isStartExamSessionInFlight] = useStartExamSession();

  const [currentQuizIndex, setCurrentQuizIndex] = useState<number>(0);

  const currentQuiz = examQuestionsWithSubmissions[currentQuizIndex];

  return (
    <div className="px-4 py-3">
      <div className="mb-2 flex flex-wrap items-center gap-4">
        <h2 className="text-2xl font-bold">{title}</h2>
        <Badge
          variant={
            data.status === "PASSED"
              ? "success"
              : data.status === "FAILED"
                ? "destructive"
                : "warning"
          }
          className=""
        >
          {data.status === "PASSED"
            ? "Başarılı"
            : data.status === "FAILED"
              ? "Başarısız"
              : "Notlandırma devam ediyor"}
        </Badge>
        {viewerCanStartSession && (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                size="sm"
                variant={data.status === "PASSED" ? "outline" : "default"}
                loading={isStartExamSessionInFlight}
                onClick={() => {}}
              >
                Tekrar Çöz
                <LapTimerIcon className="ml-1.5 size-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Sınavı tekrar çözmek istediğine emin misiniz?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Sınavı tekrar çözmeye başlamadan önce ihtiyacın varsa
                  dinlenmeni ve önceki denemendeki hatalarını gözden geçirmeni
                  tavsiye ediyoruz.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Vazgeç</AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => {
                    if (isStartExamSessionInFlight) {
                      return;
                    }
                    startExamSession({
                      variables: {
                        input: {
                          examId: examId,
                          itemId: itemId,
                        },
                      },
                    });
                  }}
                >
                  Sınavı Tekrar Çöz
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
      <p className="max-w-prose">
        Bu sınavı{" "}
        {format(
          data.finishedAt ? parseISO(data.finishedAt) : new Date(),
          "PPp",
          {
            locale: tr,
          },
        )}{" "}
        tarihinde tamamladın. Toplamda{" "}
        <span className="font-semibold">
          {data.points}/{data.maxPoints}
        </span>{" "}
        puan aldın.
      </p>
      {totalPendingQuizGrades > 0 ? (
        <p className="max-w-prose">
          Sınavın notlandırılması henüz tamamlanmadı. Henüz notlandırılmayan{" "}
          <span className="font-semibold">{totalPendingQuizGrades}</span> puan
          değerinde soru var.
        </p>
      ) : null}
      {passingGradePercentage ? (
        <p className="max-w-prose">
          Sınavı geçmek için gereken minimum puan yüzdesi:{" "}
          <span className="font-semibold">%{passingGradePercentage}</span>
        </p>
      ) : null}
      {currentQuiz ? (
        <FinishedExamQuiz
          examQuiz={currentQuiz.examQuiz}
          submission={currentQuiz.submission}
          index={currentQuizIndex}
        />
      ) : null}
      <Pagination className="pt-4">
        <PaginationPrevious
          disabled={currentQuizIndex === 0}
          aria-label="Önceki soruya dön"
          onClick={() => {
            setCurrentQuizIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : 0,
            );
          }}
        >
          Önceki soru
        </PaginationPrevious>
        <PaginationContent className="overflow-x-auto">
          {examQuestionsWithSubmissions.map((quizWithSubmission, index) => (
            <PaginationItem key={quizWithSubmission.examQuiz.id}>
              <PaginationLink
                onClick={() => setCurrentQuizIndex(index)}
                className={cn({
                  "bg-amber-200":
                    quizWithSubmission.submission?.status === "GRADE_PENDING",
                  "bg-green-200 dark:bg-green-800":
                    quizWithSubmission.submission?.status === "GRADED" &&
                    quizWithSubmission.submission?.grade &&
                    quizWithSubmission.submission?.grade > 0.5,
                  "bg-red-200 dark:bg-green-800":
                    quizWithSubmission.submission?.status === "GRADED" &&
                    quizWithSubmission.submission?.grade !== null &&
                    quizWithSubmission.submission?.grade !== undefined &&
                    quizWithSubmission.submission?.grade <= 0.5,
                  "bg-primary/10 dark:bg-primary/10":
                    index === currentQuizIndex,
                })}
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        </PaginationContent>

        <PaginationNext
          aria-label="Sonraki soruya geç"
          disabled={currentQuizIndex >= examQuestionsWithSubmissions.length - 1}
          onClick={() => {
            if (currentQuizIndex < examQuestionsWithSubmissions.length - 1) {
              setCurrentQuizIndex(currentQuizIndex + 1);
            }
          }}
        >
          Sonraki Soru
        </PaginationNext>
      </Pagination>
      {data.status === "PASSED" ? <PassedExamConfetti /> : null}
    </div>
  );
};

const PassedExamConfetti: React.FC = () => {
  const { width, height } = useWindowSize();

  return (
    <Confetti
      width={width ?? undefined}
      recycle={false}
      numberOfPieces={500}
      height={height ?? undefined}
    />
  );
};

export default FinishedExamSessionResults;
