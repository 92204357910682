"use client";

import { graphql, useFragment } from "react-relay";
import CourseItemAttachmentsSection from "@/courses/components/CourseItemAttachmentsSection";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import dynamic from "next/dynamic";
import { ArticleCourseItem$key } from "@generated/ArticleCourseItem.graphql";
import { WrenchIcon } from "lucide-react";
import { cn } from "@/lib/utils";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

export const ArticleCourseItem: React.FC<{
  item: ArticleCourseItem$key;
  mode: "learn" | "store";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, mode, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment ArticleCourseItem on ArticleCourseItem {
        id
        title
        content
        viewerProgress {
          status
          progress
        }
        ...CourseItemAttachmentsSection_attachments
      }
    `,
    item,
  );

  return (
    <>
      {mode === "store" ? (
        <div className={cn("flex items-center gap-x-2")}>
          <h2 className="flex-1 truncate text-lg font-bold text-foreground">
            {data.title}
          </h2>
        </div>
      ) : null}
      {data.content ? (
        <PlateEditor value={data.content} readOnly dense />
      ) : (
        <div
          className={cn("px-4 pt-4 text-center sm:pt-8", {
            "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <WrenchIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Bu içerik henüz yayınlanmadı.
          </h3>
          <p className="mt-1 text-sm text-foreground/60">
            Lütfen eğitmeninizle iletişime geçin.
          </p>
        </div>
      )}
      {mode === "learn" && (
        <>
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            <NextItemButton completeOnNext={Boolean(data.content)} />
          </div>
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
          </div>
        </>
      )}
      <CourseItemAttachmentsSection
        attachments={data}
        courseContentsSide={courseContentsSide}
      />
    </>
  );
};

export default ArticleCourseItem;
