import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { type useFinishExamSessionMutation } from "@generated/useFinishExamSessionMutation.graphql";

const useFinishExamSession = () => {
  const [commitFinishExamSession, isInFlight] =
    useMutation<useFinishExamSessionMutation>(graphql`
      mutation useFinishExamSessionMutation($input: FinishExamSessionInput!) {
        finishExamSession(input: $input) {
          examSession {
            id
            item {
              id
              viewerCanStartSession
            }
            ...FinishedExamSessionResults_session
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const finishExamSession = useCallback(
    (
      args: Omit<
        UseMutationConfig<useFinishExamSessionMutation>,
        "variables"
      > & {
        variables: {
          input: UseMutationConfig<useFinishExamSessionMutation>["variables"]["input"] & {
            itemId: string;
          };
        };
      },
    ) => {
      const { itemId, ...input } = args.variables.input;

      return commitFinishExamSession({
        ...args,
        variables: {
          input: input,
        },
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.finishExamSession.userErrors.length > 0 ||
            !mutationData.finishExamSession.examSession
          ) {
            return;
          }

          const newNode = store.get(
            mutationData.finishExamSession.examSession.id,
          )!;

          if (itemId) {
            const itemRecord = store.get(itemId);

            if (itemRecord) {
              itemRecord.setValue(null, "viewerCurrentSession");
              itemRecord.setLinkedRecord(
                newNode,
                "viewerLatestFinishedSession",
              );

              // update best exam session if needed
              const bestSessionRecord = itemRecord.getLinkedRecord(
                "viewerBestFinishedSession",
              );

              if (bestSessionRecord) {
                const previousBestScore = (bestSessionRecord.getValue(
                  "score",
                ) ?? 0) as number;
                const previousBestMaxScore = (bestSessionRecord.getValue(
                  "maxScore",
                ) ?? Number.MAX_SAFE_INTEGER) as number;

                const currentScore = (newNode.getValue("score") ?? 0) as number;
                const currentMaxScore = (newNode.getValue("maxScore") ??
                  Number.MAX_SAFE_INTEGER) as number;

                if (
                  currentScore / currentMaxScore >
                  previousBestScore / previousBestMaxScore
                ) {
                  itemRecord.setLinkedRecord(
                    newNode,
                    "viewerBestFinishedSession",
                  );
                }
              } else {
                // if there is no best session, set the current session as the best
                itemRecord.setLinkedRecord(
                  newNode,
                  "viewerBestFinishedSession",
                );
              }
            }
          }
        },
      });
    },
    [commitFinishExamSession],
  );

  return [finishExamSession, isInFlight] as const;
};

export default useFinishExamSession;
