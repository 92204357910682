import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { useSubmitAssignmentSubmissionMutation } from "@generated/useSubmitAssignmentSubmissionMutation.graphql";


const useSubmitAssignmentSubmission = () => {
  const [commitSubmitAssignmentSubmission, isInFlight] = useMutation<useSubmitAssignmentSubmissionMutation>(
    graphql`
      mutation useSubmitAssignmentSubmissionMutation($input: SubmitAssignmentSubmissionInput!) {
        submitAssignmentSubmission(input: $input) {
          assignmentSubmission {
            id
            ...SubmittedAssignmentCourseItem_submission
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `,
  );
  
  const submitAssignmentSubmission = useCallback(
    (args: UseMutationConfig<useSubmitAssignmentSubmissionMutation>) => {
      return commitSubmitAssignmentSubmission({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.submitAssignmentSubmission.userErrors.length > 0 ||
            !mutationData.submitAssignmentSubmission.assignmentSubmission
          ) {
            return;
          }

          const assignmentSubmissionRecord = store.get(
            mutationData.submitAssignmentSubmission.assignmentSubmission.id,
          )!;

          const itemId = args.variables.input.submissionId;

          if (itemId) {
            const itemRecord = store.get(itemId)!;

            itemRecord.setLinkedRecord(
              assignmentSubmissionRecord,
              "viewerLatestSubmission",
            );

          }
        },
      });
    },
    [commitSubmitAssignmentSubmission],
  );

  return [submitAssignmentSubmission, isInFlight] as const;
};

export default useSubmitAssignmentSubmission;
