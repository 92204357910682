/**
 * @generated SignedSource<<ab7fdc9581a45358c276c39a1e831c75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemProgressStatus = "COMPLETED" | "IN_PROGRESS" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ArticleCourseItem$data = {
  readonly content: any | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly viewerProgress: {
    readonly progress: number;
    readonly status: ItemProgressStatus;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments">;
  readonly " $fragmentType": "ArticleCourseItem";
};
export type ArticleCourseItem$key = {
  readonly " $data"?: ArticleCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleCourseItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCourseItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseItemProgress",
      "kind": "LinkedField",
      "name": "viewerProgress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    }
  ],
  "type": "ArticleCourseItem",
  "abstractKey": null
};

(node as any).hash = "c553c2b8f6c0c91065b977ffdd47eee7";

export default node;
