/**
 * @generated SignedSource<<d4632ee8571bbeea7d18f7dfe9c1d7d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SubmitAssignmentSubmissionInput = {
  submissionId: string;
};
export type useSubmitAssignmentSubmissionMutation$variables = {
  input: SubmitAssignmentSubmissionInput;
};
export type useSubmitAssignmentSubmissionMutation$data = {
  readonly submitAssignmentSubmission: {
    readonly assignmentSubmission: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssignmentCourseItem_submission">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useSubmitAssignmentSubmissionMutation = {
  response: useSubmitAssignmentSubmissionMutation$data;
  variables: useSubmitAssignmentSubmissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubmitAssignmentSubmissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitAssignmentSubmissionPayload",
        "kind": "LinkedField",
        "name": "submitAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "assignmentSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubmittedAssignmentCourseItem_submission"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSubmitAssignmentSubmissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitAssignmentSubmissionPayload",
        "kind": "LinkedField",
        "name": "submitAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "assignmentSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeResponseAnswer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grade",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gradeFeedback",
                "storageKey": null
              },
              {
                "alias": "assignmentStatus",
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efb702fda9213ec62184fb3586f02d0b",
    "id": null,
    "metadata": {},
    "name": "useSubmitAssignmentSubmissionMutation",
    "operationKind": "mutation",
    "text": "mutation useSubmitAssignmentSubmissionMutation(\n  $input: SubmitAssignmentSubmissionInput!\n) {\n  submitAssignmentSubmission(input: $input) {\n    assignmentSubmission {\n      id\n      ...SubmittedAssignmentCourseItem_submission\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment AssignmentCourseItemAttachment_attachment on Attachment {\n  id\n  title\n  createdAt\n  fileName\n  fileSize\n  fileType\n  ...DeleteAssignmentCourseItemAttachmentDialogContent_attachment\n  ...UpdateAttachmentDialog_attachment\n}\n\nfragment DeleteAssignmentCourseItemAttachmentDialogContent_attachment on Attachment {\n  id\n  title\n}\n\nfragment SubmittedAssignmentCourseItem_submission on AssignmentSubmission {\n  id\n  freeResponseAnswer\n  grade\n  gradeFeedback\n  assignmentStatus: status\n  attachments {\n    id\n    ...AssignmentCourseItemAttachment_attachment\n  }\n}\n\nfragment UpdateAttachmentDialog_attachment on Attachment {\n  id\n  title\n  fileName\n}\n"
  }
};
})();

(node as any).hash = "44f6830211e35164dba1ca2e4d689798";

export default node;
