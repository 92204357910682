"use client";

import { useCallback, useState } from "react";
import { graphql, useFragment } from "react-relay";
import useExamQuizzesWithSubmissions from "@/courses/exam/hooks/useExamQuizzesWithSubmissions";
import ExamQuiz from "@/courses/exam/components/ExamQuiz";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { cn } from "@/lib/utils";
import { CurrentExamSession_item$key } from "@generated/CurrentExamSession_item.graphql";
import { Button } from "@/components/ui/button";
import { BookCheckIcon } from "lucide-react";
import useFinishExamSession from "@/courses/exam/hooks/useFinishExamSession";
import { ExitIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

const CurrentExamSession: React.FC<{
  item: CurrentExamSession_item$key;
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment CurrentExamSession_item on ExamCourseItem {
        id
        title
        viewerCurrentSession @required(action: THROW) {
          id
          status
          startedAt
          currentExamQuizIndex
          ...useExamQuizzesWithSubmissions_examSession
        }
        allowSkip
        allowBack
        timeLimitSeconds
      }
    `,
    item,
  );

  const [currentQuizIndex, setCurrentQuizIndex] = useState<number>(
    data.viewerCurrentSession.currentExamQuizIndex ?? 0,
  );

  const goToNextQuiz = useCallback(() => {
    setCurrentQuizIndex((i) => i + 1);
  }, []);

  const examQuestionsWithSubmissions = useExamQuizzesWithSubmissions({
    examSession: data.viewerCurrentSession,
  });

  const didStudentAnswerAllQuizzes =
    data.viewerCurrentSession.currentExamQuizIndex === null ||
    data.viewerCurrentSession.currentExamQuizIndex === undefined;

  const [finishExamSession, isFinishExamSessionInFlight] =
    useFinishExamSession();

  if (!data.allowBack && didStudentAnswerAllQuizzes) {
    return (
      <div
        className={cn("px-4 pt-4 text-center sm:pt-8", {
          "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
        })}
      >
        <BookCheckIcon className="mx-auto size-12 text-primary" />
        <h3 className="mt-2 text-sm font-semibold text-foreground">
          Tüm soruları cevapladın
        </h3>
        <p className="mt-1 text-sm text-foreground/60">
          Sınavı bitirip yanıtlarını kontrol edebilirsin.
        </p>
        <Button
          className="mt-6"
          loading={isFinishExamSessionInFlight}
          onClick={() => {
            if (isFinishExamSessionInFlight) return;
            finishExamSession({
              variables: {
                input: {
                  examSessionId: data.viewerCurrentSession.id,
                  itemId: data.id,
                },
              },
            });
          }}
        >
          Sınavı Bitir
          <ExitIcon className="ml-2 size-4" />
        </Button>
      </div>
    );
  }

  const currentQuiz = examQuestionsWithSubmissions[currentQuizIndex];

  return (
    <div className="p-4 sm:py-8">
      {currentQuiz ? (
        <ExamQuiz
          sessionId={data.viewerCurrentSession.id}
          examQuiz={currentQuiz.examQuiz}
          submission={currentQuiz.submission}
          onSubmit={goToNextQuiz}
          allowSkip={data.allowSkip}
          key={currentQuiz.examQuiz.id}
        />
      ) : (
        <div className="text-center">
          <BookCheckIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Tüm soruları cevapladın
          </h3>
          <p className="mt-1 text-sm text-foreground/60">
            Sınavı bitirip yanıtlarını kontrol edebilirsin. Dilersen kalan
            süreni kullanarak sınavı bitirmeden önce cevaplarını gözden
            geçirebilirsin.
          </p>
          <Button
            className="mt-6"
            loading={isFinishExamSessionInFlight}
            onClick={() => {
              if (isFinishExamSessionInFlight) return;
              finishExamSession({
                variables: {
                  input: {
                    examSessionId: data.viewerCurrentSession.id,
                    itemId: data.id,
                  },
                },
              });
            }}
          >
            Sınavı Bitir
            <ExitIcon className="ml-2 size-4" />
          </Button>
        </div>
      )}
      <div className="flex items-center justify-between py-4">
        <Pagination>
          <PaginationPrevious
            disabled={!data.allowBack || currentQuizIndex === 0}
            aria-label="Önceki soruya dön"
            onClick={() => {
              setCurrentQuizIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : 0,
              );
            }}
          >
            Önceki soru
          </PaginationPrevious>
          <PaginationContent className="overflow-x-auto">
            {examQuestionsWithSubmissions.map((quizWithSubmission, index) => (
              <PaginationItem key={quizWithSubmission.examQuiz.id}>
                <PaginationLink
                  disabled={
                    !data.allowBack ||
                    (!didStudentAnswerAllQuizzes &&
                      index > data.viewerCurrentSession.currentExamQuizIndex)
                  }
                  onClick={() => setCurrentQuizIndex(index)}
                  className={cn({
                    "bg-primary/20":
                      quizWithSubmission.submission !== null &&
                      quizWithSubmission.submission.status !== "SKIPPED" &&
                      quizWithSubmission.submission.status !== "DRAFT",
                    "bg-amber-200":
                      quizWithSubmission.submission !== null &&
                      quizWithSubmission.submission.status === "DRAFT",
                    "bg-primary/10": index === currentQuizIndex,
                  })}
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </PaginationContent>
          {(
            didStudentAnswerAllQuizzes
              ? currentQuizIndex >= examQuestionsWithSubmissions.length - 1
              : currentQuizIndex >=
                data.viewerCurrentSession.currentExamQuizIndex
          ) ? null : (
            <PaginationNext
              aria-label="Sonraki soruya geç"
              disabled={
                didStudentAnswerAllQuizzes
                  ? currentQuizIndex >= examQuestionsWithSubmissions.length - 1
                  : currentQuizIndex >=
                    data.viewerCurrentSession.currentExamQuizIndex
              }
              onClick={() => {
                if (
                  currentQuizIndex <
                  examQuestionsWithSubmissions.length - 1
                ) {
                  setCurrentQuizIndex(currentQuizIndex + 1);
                }
              }}
            >
              Sonraki Soru
            </PaginationNext>
          )}
        </Pagination>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="destructive" className="ml-auto">
              Sınavı Bitir
              <ExitIcon className="ml-2 size-4" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Sınavı bitirmek istediğinize emin misiniz?
              </AlertDialogTitle>
              <AlertDialogDescription>
                Sınavı bitirdiğinizde cevaplarınız gönderilecek ve sınav
                sonuçlarınız görüntülenecektir.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Geri Dön</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  if (isFinishExamSessionInFlight) return;

                  finishExamSession({
                    variables: {
                      input: {
                        examSessionId: data.viewerCurrentSession.id,
                        itemId: data.id,
                      },
                    },
                  });
                }}
              >
                Sınavı Bitir
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};

export default CurrentExamSession;
