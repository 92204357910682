/**
 * @generated SignedSource<<3e8a1f969b37f7c98d50df67acda13ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CourseItemVideoPlayer_progress$data = {
  readonly viewerProgress: {
    readonly progress: number;
  } | null | undefined;
  readonly " $fragmentType": "CourseItemVideoPlayer_progress";
};
export type CourseItemVideoPlayer_progress$key = {
  readonly " $data"?: CourseItemVideoPlayer_progress$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemVideoPlayer_progress">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseItemVideoPlayer_progress",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseItemProgress",
      "kind": "LinkedField",
      "name": "viewerProgress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseItem",
  "abstractKey": "__isCourseItem"
};

(node as any).hash = "653ec9094487e503ba6a88bce048779f";

export default node;
