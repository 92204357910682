/**
 * @generated SignedSource<<be96209a28d7b7175aafdb6e729f5587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CourseItemAttachmentsSection_attachments$data = {
  readonly attachments: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AttachmentDownloadButton_attachment">;
  }>;
  readonly id: string;
  readonly " $fragmentType": "CourseItemAttachmentsSection_attachments";
};
export type CourseItemAttachmentsSection_attachments$key = {
  readonly " $data"?: CourseItemAttachmentsSection_attachments$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseItemAttachmentsSection_attachments",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AttachmentDownloadButton_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseItem",
  "abstractKey": "__isCourseItem"
};
})();

(node as any).hash = "de373099675f22a29cd495a9ddee9824";

export default node;
