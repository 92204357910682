import { graphql, useMutation, type UseMutationConfig } from "react-relay";
import { useCallback } from "react";
import { type useSubmitQuizMutation } from "@generated/useSubmitQuizMutation.graphql";

const useSubmitQuiz = () => {
  const [commitSubmitQuiz, isInFlight] = useMutation<useSubmitQuizMutation>(
    graphql`
      mutation useSubmitQuizMutation($input: SubmitQuizInput!) {
        submitQuiz(input: $input) {
          quizSubmission {
            id
            ...SubmittedQuizCourseItem_submission
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `,
  );

  const submitQuiz = useCallback(
    (args: UseMutationConfig<useSubmitQuizMutation>) => {
      return commitSubmitQuiz({
        ...args,
        updater: (store, mutationData) => {
          if (args.updater) {
            args.updater(store, mutationData);
          }

          if (
            mutationData === null ||
            mutationData === undefined ||
            mutationData.submitQuiz.userErrors.length > 0 ||
            !mutationData.submitQuiz.quizSubmission
          ) {
            return;
          }

          const quizSubmissionRecord = store.get(
            mutationData.submitQuiz.quizSubmission.id,
          )!;

          const itemId = args.variables.input.itemId;

          if (itemId) {
            const itemRecord = store.get(itemId)!;

            itemRecord.setLinkedRecord(
              quizSubmissionRecord,
              "viewerLatestSubmission",
            );

            // update best submission if needed
            const bestSubmissionRecord = itemRecord.getLinkedRecord(
              "viewerBestSubmission",
            );

            if (
              !bestSubmissionRecord ||
              (bestSubmissionRecord.getValue("grade") ?? 0) <
                (quizSubmissionRecord.getValue("grade") ?? 0)
            ) {
              itemRecord.setLinkedRecord(
                quizSubmissionRecord,
                "viewerBestSubmission",
              );
            }
          }
        },
      });
    },
    [commitSubmitQuiz],
  );

  return [submitQuiz, isInFlight] as const;
};

export default useSubmitQuiz;
