/**
 * @generated SignedSource<<30cd354969c35d9157058e7bf63917aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizSubmissionStatus = "DRAFT" | "GRADED" | "GRADE_PENDING" | "SKIPPED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type FinishedExamQuiz_submission$data = {
  readonly freeResponseAnswer: any | null | undefined;
  readonly grade: number | null | undefined;
  readonly id: string;
  readonly secondsSpent: number;
  readonly selectedChoiceIndex: number | null | undefined;
  readonly startedAt: string;
  readonly status: QuizSubmissionStatus;
  readonly submittedAt: string;
  readonly " $fragmentType": "FinishedExamQuiz_submission";
};
export type FinishedExamQuiz_submission$key = {
  readonly " $data"?: FinishedExamQuiz_submission$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishedExamQuiz_submission">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinishedExamQuiz_submission",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedChoiceIndex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeResponseAnswer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondsSpent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    }
  ],
  "type": "StudentQuizSubmission",
  "abstractKey": null
};

(node as any).hash = "c245f84e54b084055c16345d51b88dc3";

export default node;
