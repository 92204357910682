"use client";

import {
  useCourseAcademicTerm,
  useCurrentItem,
  useNextItem,
} from "@/courses/context/CourseContext";
import { Button } from "@/components/ui/button";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useOrganization } from "@/student/context/OrganizationContext";
import useUpdateItemProgress from "@/courses/learn/hooks/useUpdateItemProgress";
import PiLink from "@/components/PiLink";

interface Props {
  disabled?: boolean;
  completeOnNext?: boolean;
}

const NextItemButton: React.FC<Props> = ({
  disabled = false,
  completeOnNext = false,
}) => {
  const { slug: orgSlug } = useOrganization();
  const {
    slug: academicTermSlug,
    course: { slug: courseSlug },
  } = useCourseAcademicTerm();

  const currentItem = useCurrentItem();
  const nextItem = useNextItem();

  const [updateItemProgress, isUpdateItemProgressInFlight] =
    useUpdateItemProgress();

  if (!nextItem) return null;

  if (disabled) {
    return (
      <Button disabled variant="default">
        Sonraki <span className="hidden whitespace-pre sm:inline"> İçerik</span>
        <ChevronRightIcon className="ml-2 size-4" />
      </Button>
    );
  }

  return (
    <Button
      variant="default"
      asChild
      onClick={() => {
        if (completeOnNext && currentItem && !isUpdateItemProgressInFlight) {
          updateItemProgress({
            variables: {
              input: {
                itemId: currentItem?.id,
                completed: true,
              },
            },
          });
        }
      }}
    >
      <PiLink
        href={`/${orgSlug}/dersler/ogren/${courseSlug}/${academicTermSlug}/${nextItem.slug}`}
      >
        Sonraki <span className="hidden whitespace-pre sm:inline"> İçerik</span>
        <ChevronRightIcon className="ml-2 size-4" />
      </PiLink>
    </Button>
  );
};

export default NextItemButton;
