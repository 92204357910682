/**
 * @generated SignedSource<<37cd329dfe878c768804ac7340407874>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoSource = "PI" | "URL" | "VIMEO" | "YOUTUBE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type CourseItemVideoPlayer_video$data = {
  readonly chaptersVttUrl: string | null | undefined;
  readonly duration: number | null | undefined;
  readonly externalUrl: string | null | undefined;
  readonly id: string;
  readonly inVideoQuizzes: ReadonlyArray<{
    readonly allowSkip: boolean;
    readonly id: string;
    readonly quiz: {
      readonly id: string;
      readonly options?: ReadonlyArray<{
        readonly content: any | null | undefined;
        readonly description: any | null | undefined;
        readonly isCorrect: boolean;
      }>;
      readonly question: any | null | undefined;
      readonly sampleAnswer?: any;
      readonly title: string;
    };
    readonly showAtProgress: number;
  }> | null | undefined;
  readonly posterUrl: string | null | undefined;
  readonly source: VideoSource;
  readonly thumbnailsUrl: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "CourseItemVideoPlayer_video";
};
export type CourseItemVideoPlayer_video$key = {
  readonly " $data"?: CourseItemVideoPlayer_video$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemVideoPlayer_video">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseItemVideoPlayer_video",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chaptersVttUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "posterUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailsUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InVideoQuiz",
      "kind": "LinkedField",
      "name": "inVideoQuizzes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSkip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showAtProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "quiz",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "question",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuizOption",
                  "kind": "LinkedField",
                  "name": "options",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "content",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCorrect",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "MultipleChoiceQuiz",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sampleAnswer",
                  "storageKey": null
                }
              ],
              "type": "FreeResponseQuiz",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
};
})();

(node as any).hash = "c0e5e2432b5942854c98455259bedb2f";

export default node;
