/**
 * @generated SignedSource<<4698f83a3b5b0a93560f2f450b58ec2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AnswerQuizCourseItem$data = {
  readonly allowSkip: boolean;
  readonly id: string;
  readonly quiz: {
    readonly __typename: string;
    readonly id: string;
    readonly options?: ReadonlyArray<{
      readonly content: any | null | undefined;
      readonly description: any | null | undefined;
      readonly isCorrect: boolean;
    }>;
    readonly question: any | null | undefined;
    readonly sampleAnswer?: any;
    readonly solutionVideo: {
      readonly " $fragmentSpreads": FragmentRefs<"CourseItemVideoPlayer_video">;
    } | null | undefined;
    readonly solutionVideoCookiesUrl: string | null | undefined;
    readonly title: string;
  };
  readonly title: string;
  readonly " $fragmentType": "AnswerQuizCourseItem";
};
export type AnswerQuizCourseItem$key = {
  readonly " $data"?: AnswerQuizCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnswerQuizCourseItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnswerQuizCourseItem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "quiz",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "question",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuizOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCorrect",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MultipleChoiceQuiz",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sampleAnswer",
                "storageKey": null
              }
            ],
            "type": "FreeResponseQuiz",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "solutionVideo",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CourseItemVideoPlayer_video"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "solutionVideoCookiesUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "quiz"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSkip",
      "storageKey": null
    }
  ],
  "type": "QuizCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "dc0740e8dd6ebf47ed70b01dd67373e2";

export default node;
