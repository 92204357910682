"use client";
import { SubmittedAssignmentCourseItem$key } from "@generated/SubmittedAssignmentCourseItem.graphql";
import { SubmittedAssignmentCourseItem_submission$key } from "@generated/SubmittedAssignmentCourseItem_submission.graphql";
import { graphql, useFragment } from "react-relay";
import { useState } from "react";
import dynamic from "next/dynamic";
import { useOrganization } from "@/student/context/OrganizationContext";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import { Button } from "@/components/ui/button";
import {
  LapTimerIcon,
  PaperPlaneIcon,
  Pencil2Icon,
} from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { CaretSortIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Alert } from "@/components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useToast } from "@/components/ui/use-toast";
import useUpdateAssignmentSubmission from "@/courses/assignment/hooks/useUpdateAssignmentSubmission";
import useSubmitAssignmentSubmission from "../hooks/useSubmitAssignmentSubmission";
import useCreateDraftAssignmentSubmission from "../hooks/useCreateDraftAssignmentSubmission";
import AssignmentCourseItemAttachment from "./AssignmentCourseItemAttachment";

const AssignmentAttachmentUploader = dynamic(
  () => import("./AssignmentAttachmentUploader"),
  {
    ssr: false,
  },
);

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const SubmittedAssignmentCourseItem: React.FC<{
  item: SubmittedAssignmentCourseItem$key;
  submission: SubmittedAssignmentCourseItem_submission$key;
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, submission, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment SubmittedAssignmentCourseItem on AssignmentCourseItem {
        id
        title
        ...CourseItemAttachmentsSection_attachments
        description
        allowRichText
        allowFileUpload
        allowMultipleSubmissions
      }
    `,
    item,
  );

  const submissionData = useFragment(
    graphql`
      fragment SubmittedAssignmentCourseItem_submission on AssignmentSubmission {
        id
        freeResponseAnswer
        grade
        gradeFeedback
        assignmentStatus: status
        attachments {
          id
          ...AssignmentCourseItemAttachment_attachment
        }
      }
    `,
    submission,
  );

  const organization = useOrganization();
  const { toast } = useToast();

  const [freeResponseAnswer, setFreeResponseAnswer] = useState<any>(null);

  const [commitUpdateAssignmentSubmission, isUpdateInFlight] =
    useUpdateAssignmentSubmission();

  const [commitSubmitAssignmentSubmission, isSubmitInFlight] =
    useSubmitAssignmentSubmission();

  const [commitCreateDraftAssignmentSubmission, isCreateDraftFlight] =
    useCreateDraftAssignmentSubmission();

  if (submissionData.assignmentStatus === "DRAFT") {
    return (
      <section className="mt-2">
        <div className="gap-4 pb-4">
          <h2 className="text-2xl font-bold">{data.title}</h2>
          <PlateEditor value={data.description} readOnly dense />
          <div className="col-span-2 mt-2 gap-4 pb-4">
            {data.allowRichText && (
              <PlateEditor
                initialValue={submissionData.freeResponseAnswer}
                onChange={setFreeResponseAnswer}
                organizationId={organization.id}
              />
            )}
            {data.allowFileUpload && (
              <Collapsible
                className={cn("group", {
                  "mt-2": data.allowRichText,
                })}
              >
                <CollapsibleTrigger className="flex items-center group-data-[state=open]:mb-2">
                  <span className="group-data-[state=closed]:hidden">
                    {submissionData.attachments.length
                      ? "Ek Dökümanlarını Gizle"
                      : "Dokümanlarını Yüklemeyi İptal Et"}
                  </span>
                  <span
                    className={cn(
                      "group-data-[state=open]:hidden flex items-center",
                      {
                        "text-destructive": !submissionData.attachments.length,
                      },
                    )}
                  >
                    {submissionData.attachments.length ? (
                      `Yüklediğin ${submissionData.attachments.length} Dökümanı Görüntüle`
                    ) : (
                      <Badge variant="destructive">
                        <ExclamationTriangleIcon className="mr-1 size-4" />
                        Herhangi Bir Döküman Yüklemedin.
                      </Badge>
                    )}
                  </span>
                  <CaretSortIcon className="ml-2 size-4" />
                </CollapsibleTrigger>
                <CollapsibleContent className="space-y-2">
                  {submissionData.attachments.map((attachment) => (
                    <AssignmentCourseItemAttachment
                      submissionId={submissionData.id}
                      attachment={attachment}
                      key={attachment.id}
                    />
                  ))}
                  {
                    <AssignmentAttachmentUploader
                      assignmentSubmissionId={submissionData.id}
                      existingAttachmentCount={
                        submissionData.attachments.length
                      }
                    />
                  }
                </CollapsibleContent>
              </Collapsible>
            )}
            <div
              className={cn("flex items-center gap-x-2 px-4 pt-4", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <h2
                className={cn(
                  "flex-1 items-center gap-x-2 truncate px-4 pt-4 text-lg font-bold text-foreground",
                  {
                    "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
                  },
                )}
              >
                {data.title}
              </h2>

              <Button
                variant="outline"
                disabled={isUpdateInFlight}
                onClick={() => {
                  if (isUpdateInFlight) {
                    return;
                  }
                  commitUpdateAssignmentSubmission({
                    variables: {
                      input: {
                        submissionId: submissionData.id,
                        freeResponseAnswer: { value: freeResponseAnswer },
                      },
                    },
                  });
                  toast({
                    title: "Ödeviniz Taslak Olarak Kaydedildi",
                  });
                }}
              >
                Ödevi Taslak Olarak Kaydet
                <Pencil2Icon className="ml-2 size-4" />
              </Button>

              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="default"
                    disabled={
                      !data.allowRichText &&
                      submissionData.attachments.length === 0
                    }
                    loading={isSubmitInFlight}
                    onClick={() => {}}
                  >
                    Ödevi Gönder
                    <PaperPlaneIcon className="ml-1.5 size-4" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Ödevi göndermek istediğine emin misin?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      Bu aksiyon geri alınamaz.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Vazgeç</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        if (isUpdateInFlight) {
                          return;
                        }
                        commitUpdateAssignmentSubmission({
                          variables: {
                            input: {
                              submissionId: submissionData.id,
                              freeResponseAnswer: { value: freeResponseAnswer },
                            },
                          },
                        });
                        if (isSubmitInFlight) {
                          return;
                        }
                        commitSubmitAssignmentSubmission({
                          variables: {
                            input: {
                              submissionId: submissionData.id,
                            },
                          },
                        });
                      }}
                    >
                      Ödevi Gönder
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
            <NextItemButton />
          </div>
        </div>
      </section>
    );
  }

  if (
    submissionData.assignmentStatus === "GRADE_PENDING" &&
    !data.allowMultipleSubmissions
  ) {
    return (
      <>
        <PlateEditor value={data.description} readOnly dense />
        <div className="col-span-2 sm:col-span-4">
          {data.allowRichText && (
            <PlateEditor
              value={submissionData.freeResponseAnswer}
              readOnly
              dense
            />
          )}
          {data.allowFileUpload && (
            <Collapsible>
              <CollapsibleContent className="space-y-2">
                {submissionData.attachments.map((attachment) => (
                  <AssignmentCourseItemAttachment
                    submissionId={submissionData.id}
                    attachment={attachment}
                    key={attachment.id}
                  />
                ))}
              </CollapsibleContent>
            </Collapsible>
          )}
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
            <NextItemButton />
          </div>
        </div>
        <Alert variant="destructive">
          Bu ödevde yalnızca bir tane cevap hakkın olduğundan başka bir cevap
          veremezsin.
        </Alert>
      </>
    );
  }
  if (
    submissionData.assignmentStatus === "GRADE_PENDING" &&
    data.allowMultipleSubmissions
  ) {
    return (
      <>
        <div className="px-4 py-3">
          <div className="mb-2 flex flex-wrap items-center gap-4">
            <h2 className="text-2xl font-bold">{data.title}</h2>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  size="sm"
                  variant="outline"
                  loading={isCreateDraftFlight}
                  onClick={() => {}}
                >
                  Ödevi Güncelle
                  <LapTimerIcon className="ml-1.5 size-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Ödevinizi güncellemek istediğinizden emin misiniz?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Vazgeç</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      if (isCreateDraftFlight) {
                        return;
                      }
                      commitCreateDraftAssignmentSubmission({
                        variables: {
                          input: {
                            itemId: data.id,
                          },
                        },
                      });
                    }}
                  >
                    Ödevi Güncelle
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
          <PlateEditor value={data.description} readOnly dense />
          <h3 className="font-semibold">Cevabın</h3>
          {data.allowRichText && (
            <PlateEditor
              value={submissionData.freeResponseAnswer}
              readOnly
              dense
            />
          )}
          {data.allowFileUpload && (
            <Collapsible
              className={cn("group", {
                "mt-2": data.allowRichText,
              })}
            >
              {submissionData.attachments.length ? (
                <CollapsibleTrigger className="flex items-center group-data-[state=open]:mb-2">
                  <span className="group-data-[state=closed]:hidden">
                    {submissionData.attachments.length
                      ? "Ek Dökümanlarını Gizle"
                      : "Dokümanlatrını Yüklemeyi İptal Et"}
                  </span>
                  <span className="flex items-center group-data-[state=open]:hidden">
                    Yüklediğin {submissionData.attachments.length} Dökümanı
                    Görüntüle
                  </span>
                  <CaretSortIcon className="ml-2 size-4" />
                </CollapsibleTrigger>
              ) : (
                <Badge variant="destructive">
                  <ExclamationTriangleIcon className="mr-1 size-4" />
                  Herhangi Bir Döküman Yüklemedin.
                </Badge>
              )}
              <CollapsibleContent className="space-y-2">
                {submissionData.attachments.map((attachment) => (
                  <AssignmentCourseItemAttachment
                    submissionId={submissionData.id}
                    attachment={attachment}
                    key={attachment.id}
                  />
                ))}
              </CollapsibleContent>
            </Collapsible>
          )}
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            <NextItemButton />
          </div>
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
          </div>
        </div>
      </>
    );
  }

  if (submissionData.assignmentStatus === "GRADED") {
    return (
      <>
        <p>
          Not: <strong>{submissionData.grade}</strong>
          Feedback: <strong>{submissionData.gradeFeedback}</strong>
        </p>
        <PlateEditor value={data.description} readOnly dense />
        <div className="col-span-2 sm:col-span-4">
          {data.allowRichText && (
            <PlateEditor
              value={submissionData.freeResponseAnswer}
              readOnly
              dense
            />
          )}
          {data.allowFileUpload && (
            <Collapsible
              className={cn("group", {
                "mt-2": data.allowRichText,
              })}
            >
              {submissionData.attachments.length ? (
                <CollapsibleTrigger className="flex items-center group-data-[state=open]:mb-2">
                  <span className="group-data-[state=closed]:hidden">
                    {submissionData.attachments.length
                      ? "Ek Dökümanlarını Gizle"
                      : "Dokümanlatrını Yüklemeyi İptal Et"}
                  </span>
                  <span className="flex items-center group-data-[state=open]:hidden">
                    Yüklediğin {submissionData.attachments.length} Dökümanı
                    Görüntüle
                  </span>
                  <CaretSortIcon className="ml-2 size-4" />
                </CollapsibleTrigger>
              ) : (
                <Badge variant="destructive">
                  <ExclamationTriangleIcon className="mr-1 size-4" />
                  Herhangi Bir Döküman Yüklemedin.
                </Badge>
              )}
              <CollapsibleContent className="space-y-2">
                {submissionData.attachments.map((attachment) => (
                  <AssignmentCourseItemAttachment
                    submissionId={submissionData.id}
                    attachment={attachment}
                    key={attachment.id}
                  />
                ))}
              </CollapsibleContent>
            </Collapsible>
          )}
          <div
            className={cn("flex items-center gap-x-2 px-4 pb-5 pt-2", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <ReportItemButton />
            <NextItemButton />
          </div>
        </div>
      </>
    );
  }
};

export default SubmittedAssignmentCourseItem;
