/**
 * @generated SignedSource<<d7f5cbd98009ea6bfad1e379011bc197>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type StartExamSession_item$data = {
  readonly allowBack: boolean;
  readonly allowSkip: boolean;
  readonly exam: {
    readonly id: string;
    readonly quizzes: ReadonlyArray<{
      readonly __typename: "ExamQuiz";
      readonly id: string;
    }>;
  };
  readonly id: string;
  readonly instructions: any | null | undefined;
  readonly passingGradePercentage: number | null | undefined;
  readonly timeLimitSeconds: number | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "StartExamSession_item";
};
export type StartExamSession_item$key = {
  readonly " $data"?: StartExamSession_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartExamSession_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartExamSession_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSkip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowBack",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeLimitSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passingGradePercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instructions",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamQuiz",
            "kind": "LinkedField",
            "name": "quizzes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "exam"
    }
  ],
  "type": "ExamCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "32d0dd30e553482929056541f5611705";

export default node;
