/**
 * @generated SignedSource<<bf4ca05a9f6efb515e1636d213d990c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type ExamSessionStatus = "FAILED" | "GRADE_PENDING" | "IN_PROGRESS" | "PASSED" | "PAUSED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type FinishedExamSessionResults_session$data = {
  readonly finishedAt: string | null | undefined;
  readonly id: string;
  readonly maxPoints: number;
  readonly points: number | null | undefined;
  readonly status: ExamSessionStatus;
  readonly " $fragmentSpreads": FragmentRefs<"useFinishedExamQuizzesWithSubmissions_examSession">;
  readonly " $fragmentType": "FinishedExamSessionResults_session";
};
export type FinishedExamSessionResults_session$key = {
  readonly " $data"?: FinishedExamSessionResults_session$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishedExamSessionResults_session">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinishedExamSessionResults_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxPoints",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFinishedExamQuizzesWithSubmissions_examSession"
    }
  ],
  "type": "ExamSession",
  "abstractKey": null
};

(node as any).hash = "ca866e51c063597a2d27e2438436eead";

export default node;
