"use client";

import dynamic from "next/dynamic";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogTrigger } from "@/components/plate-ui/dialog";
import { Button } from "@/components/ui/button";
import { Pencil2Icon } from "@radix-ui/react-icons";

interface Props {
  answer: any;
}

const ShowAnswerButton: React.FC<Props> = ({ answer }) => {
  if (!answer) return null;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <span className="sm:hidden">Cevap</span>
          <span className="hidden sm:inline">Cevabı Göster</span>
          <Pencil2Icon className={"ml-2 h-4 w-4"} />{" "}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-2xl lg:max-w-4xl">
        <DialogHeader>
          <DialogTitle>Cevap</DialogTitle>
        </DialogHeader>
        <PlateEditor value={answer} readOnly dense />
      </DialogContent>
    </Dialog>
  );
};

export default ShowAnswerButton;
