"use client";

import { graphql, useFragment } from "react-relay";
import { Button } from "@/components/ui/button";
import dynamic from "next/dynamic";
import { cn } from "@/lib/utils";
import { FinishedExamQuiz_submission$key } from "@generated/FinishedExamQuiz_submission.graphql";
import { FinishedExamQuiz$key } from "@generated/FinishedExamQuiz.graphql";
import React, { useState } from "react";
import { InfoCircledIcon, Pencil2Icon, VideoIcon } from "@radix-ui/react-icons";
import CourseItemVideoPlayer from "@/courses/components/CourseItemVideoPlayer";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Alert, AlertTitle } from "@/components/ui/alert";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const FinishedExamQuiz: React.FC<{
  examQuiz: FinishedExamQuiz$key;
  submission: FinishedExamQuiz_submission$key | null;
  index: number;
}> = ({ examQuiz, submission, index }) => {
  const data = useFragment(
    graphql`
      fragment FinishedExamQuiz on ExamQuiz {
        id
        points
        quiz {
          id
          question
          title
          __typename
          solutionVideo {
            ...CourseItemVideoPlayer_video
          }
          solutionVideoCookiesUrl
          ... on MultipleChoiceQuiz {
            options {
              content
              description
              isCorrect
            }
          }
          ... on FreeResponseQuiz {
            sampleAnswer
          }
        }
      }
    `,
    examQuiz,
  );

  const submissionData = useFragment(
    graphql`
      fragment FinishedExamQuiz_submission on StudentQuizSubmission {
        id
        selectedChoiceIndex
        freeResponseAnswer
        secondsSpent
        startedAt
        submittedAt
        status
        grade
      }
    `,
    submission,
  );

  const [mode, setMode] = useState<"question" | "solution">("question");

  const submissionGrade = data.points * (submissionData?.grade ?? 0);

  const submittedOption =
    submissionData &&
    submissionData.selectedChoiceIndex !== null &&
    submissionData.selectedChoiceIndex !== undefined
      ? data.quiz.options?.[submissionData.selectedChoiceIndex]
      : undefined;
  const correctOption = data.quiz.options?.find((option) => option.isCorrect);

  return (
    <section className="mt-2">
      <div className="mb-2 flex flex-wrap items-center gap-4">
        <h3 className="text-xl font-semibold">
          Soru {index + 1} (
          {submissionData?.status === "GRADE_PENDING"
            ? "?"
            : submissionGrade.toLocaleString("en", {
                useGrouping: false,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
          /{data.points} puan)
          {submissionData?.status === "GRADE_PENDING"
            ? " - Notun henüz hesaplanmadı."
            : ""}
        </h3>
        {data.quiz.solutionVideo ? (
          mode === "question" ? (
            <Button variant="outline" onClick={() => setMode("solution")}>
              <VideoIcon className="mr-2 size-4" />
              Çözümü İzle
            </Button>
          ) : (
            <Button variant="outline" onClick={() => setMode("question")}>
              <Pencil2Icon className="mr-2 size-4" />
              Soruya Dön
            </Button>
          )
        ) : null}
      </div>
      {mode === "question" ? (
        <>
          <PlateEditor
            value={data.quiz.question}
            readOnly
            className="mx-auto text-center"
            dense
          />
          <div className="grid grid-cols-2 gap-4 py-2 sm:grid-cols-4 lg:py-4">
            {data.quiz.__typename === "FreeResponseQuiz" ? (
              <div className="col-span-2 sm:col-span-4">
                <h3 className="font-semibold">Cevabın</h3>
                {submissionData?.freeResponseAnswer ? (
                  <PlateEditor
                    value={submissionData?.freeResponseAnswer}
                    readOnly
                    dense
                  />
                ) : (
                  <p className="text-sm">Bu soruyu boş bıraktın.</p>
                )}

                {data.quiz.sampleAnswer ? (
                  <>
                    <h3 className="mt-4 font-semibold">Örnek Cevap</h3>
                    <PlateEditor
                      value={data.quiz.sampleAnswer}
                      readOnly
                      dense
                    />
                  </>
                ) : null}
              </div>
            ) : (
              data.quiz.options?.map((option, index) => (
                <Button
                  size="none"
                  variant="outline"
                  key={index}
                  className={cn("col-span-2 sm:last:odd:col-start-2", {
                    "bg-primary/20":
                      submissionData?.selectedChoiceIndex === index,
                    "bg-green-200 dark:bg-green-800": option.isCorrect,
                  })}
                >
                  <PlateEditor
                    value={option.content}
                    readOnly
                    className="bg-transparent"
                    dense
                  />
                  {option.description ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <InfoCircledIcon />
                        </TooltipTrigger>
                        <TooltipContent variant="background" className="p-0">
                          <PlateEditor
                            value={option.description}
                            readOnly
                            containerClassName={cn("flex items-center")}
                          />
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : null}
                </Button>
              ))
            )}
          </div>
          {(submittedOption && submittedOption.description) ||
          (correctOption && correctOption.description) ? (
            <Alert
              variant={
                submittedOption && submittedOption.isCorrect
                  ? "success"
                  : "info"
              }
            >
              <AlertTitle className="font-bold">
                {submittedOption && submittedOption.isCorrect
                  ? "Tebrikler! Doğru cevap."
                  : "Maalesef soruyu doğru cevaplayamadın. Aşağıda cevabın açıklamasını bulabilirsin."}
              </AlertTitle>
              <PlateEditor
                value={
                  submittedOption?.description ?? correctOption?.description
                }
                readOnly
                containerClassName={cn("flex items-center")}
              />
            </Alert>
          ) : null}
        </>
      ) : (
        <CourseItemVideoPlayer
          title={data.quiz.title}
          video={data.quiz.solutionVideo!}
          itemId={data.id}
          mode="learn"
        />
      )}
    </section>
  );
};

export default FinishedExamQuiz;
