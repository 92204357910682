import {
  type useExamQuizzesWithSubmissions_examSession$data,
  type useExamQuizzesWithSubmissions_examSession$key,
} from "@generated/useExamQuizzesWithSubmissions_examSession.graphql";
import { graphql, useFragment } from "react-relay";

const useExamQuizzesWithSubmissions = ({
  examSession,
}: {
  examSession: useExamQuizzesWithSubmissions_examSession$key | null | undefined;
}): Array<{
  examQuiz: useExamQuizzesWithSubmissions_examSession$data["examQuizzes"][number];
  submission:
    | useExamQuizzesWithSubmissions_examSession$data["submissions"][number]
    | null;
}> => {
  const submissionsData = useFragment(
    graphql`
      fragment useExamQuizzesWithSubmissions_examSession on ExamSession {
        examQuizzes {
          id
          ...ExamQuiz
        }
        submissions {
          id
          examQuizId
          status
          ...ExamQuiz_submission
        }
      }
    `,
    examSession,
  );

  if (submissionsData === null || submissionsData === undefined) {
    return [];
  }

  return submissionsData.examQuizzes.map((examQuiz) => {
    const submission = submissionsData?.submissions.find(
      (submission) => submission.examQuizId === examQuiz.id,
    );

    return {
      examQuiz,
      submission: submission ?? null,
    };
  });
};

export default useExamQuizzesWithSubmissions;
