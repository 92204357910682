"use client";

import { ExamQuiz$key } from "@generated/ExamQuiz.graphql";
import { graphql, useFragment, useMutation } from "react-relay";
import { ExamQuizSubmitExamQuizMutation } from "@generated/ExamQuizSubmitExamQuizMutation.graphql";
import { ExamQuiz_submission$key } from "@generated/ExamQuiz_submission.graphql";
import { useOrganization } from "@/student/context/OrganizationContext";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import dynamic from "next/dynamic";
import { cn } from "@/lib/utils";
import { PaperPlaneIcon, ValueNoneIcon } from "@radix-ui/react-icons";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

const ExamQuiz: React.FC<{
  sessionId: string;
  examQuiz: ExamQuiz$key;
  submission: ExamQuiz_submission$key | null;
  onSubmit?: () => void;
  allowSkip: boolean;
}> = ({ sessionId, examQuiz, submission, allowSkip, onSubmit }) => {
  const organization = useOrganization();

  const data = useFragment(
    graphql`
      fragment ExamQuiz on ExamQuiz {
        id
        quiz {
          id
          question
          title
          __typename
          ... on MultipleChoiceQuiz {
            options {
              content
              description
              isCorrect
            }
          }
        }
      }
    `,
    examQuiz,
  );

  const submissionData = useFragment(
    graphql`
      fragment ExamQuiz_submission on StudentQuizSubmission {
        id
        selectedChoiceIndex
        freeResponseAnswer
        secondsSpent
        startedAt
        submittedAt
        status
      }
    `,
    submission,
  );

  const [chosenIndex, setChosenIndex] = useState<number | null>(
    submissionData?.selectedChoiceIndex ?? null,
  );
  const [freeResponseAnswer, setFreeResponseAnswer] = useState<any>(
    submissionData?.freeResponseAnswer ?? null,
  );
  const [startedAt] = useState(() => new Date());

  const [submitExamQuiz, isInFlight] =
    useMutation<ExamQuizSubmitExamQuizMutation>(graphql`
      mutation ExamQuizSubmitExamQuizMutation($input: SubmitExamQuizInput!) {
        submitExamQuiz(input: $input) {
          examSession {
            id
            secondsSpent
            status
            currentExamQuizIndex
          }
          quizSubmission {
            id
            ...ExamQuiz_submission
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  return (
    <>
      <PlateEditor
        value={data.quiz.question}
        className="mx-auto text-center lg:mt-8"
        readOnly
        dense
      />
      <div className="grid grid-cols-2 gap-4 py-2 sm:grid-cols-4 lg:py-4">
        {data.quiz.__typename === "FreeResponseQuiz" ? (
          <div className="col-span-2 sm:col-span-4">
            <PlateEditor
              initialValue={freeResponseAnswer}
              onChange={setFreeResponseAnswer}
              organizationId={organization.id}
            />
          </div>
        ) : (
          data.quiz.options?.map((option, index) => (
            <Button
              size="none"
              variant="outline"
              disabled={isInFlight}
              key={index}
              className={cn("col-span-2 sm:last:odd:col-start-2", {
                "bg-primary/20": chosenIndex === index,
              })}
              onClick={() => {
                if (isInFlight) {
                  return;
                }

                setChosenIndex(index);
              }}
            >
              <PlateEditor
                value={option.content}
                readOnly
                className="bg-transparent"
                dense
              />
            </Button>
          ))
        )}
      </div>
      <div className="flex items-center justify-center gap-2 p-2">
        {allowSkip ? (
          <Button
            disabled={Boolean(freeResponseAnswer) && freeResponseAnswer.length}
            onClick={() => {
              if (isInFlight) {
                return;
              }
              submitExamQuiz({
                variables: {
                  input: {
                    examSessionId: sessionId,
                    examQuizId: data.id,
                    submission: {
                      type: "Skip",
                    },
                    startedAt: startedAt.toISOString(),
                  },
                },
                updater: (store, mutationData) => {
                  if (
                    mutationData === null ||
                    mutationData === undefined ||
                    mutationData.submitExamQuiz.quizSubmission === null ||
                    mutationData.submitExamQuiz.quizSubmission === undefined ||
                    mutationData.submitExamQuiz.userErrors.length > 0
                  ) {
                    return;
                  }

                  const examSessionRecord = store.get(sessionId);

                  if (!examSessionRecord) {
                    return;
                  }

                  const submissions =
                    examSessionRecord.getLinkedRecords("submissions");

                  if (!submissions) {
                    return;
                  }

                  const submissionRecord = submissions.find(
                    (s) => s.getValue("examQuizId") === data.id,
                  );

                  if (submissionRecord) {
                    // Submission already updated by Relay
                    return;
                  }

                  const newSubmissionRecord = store.get(
                    mutationData.submitExamQuiz.quizSubmission.id,
                  )!;

                  examSessionRecord.setLinkedRecords(
                    [...submissions, newSubmissionRecord],
                    "submissions",
                  );
                },
                onCompleted: () => {
                  if (onSubmit) {
                    onSubmit();
                  }
                },
              });
            }}
            variant="outline"
          >
            Boş Bırak
            <ValueNoneIcon className="ml-2 size-4" />
          </Button>
        ) : null}
        <Button
          disabled={
            (data.quiz.__typename === "FreeResponseQuiz" &&
              !freeResponseAnswer) ||
            (data.quiz.__typename === "MultipleChoiceQuiz" &&
              chosenIndex === null)
          }
          loading={isInFlight}
          onClick={() => {
            if (isInFlight) {
              return;
            }
            submitExamQuiz({
              variables: {
                input: {
                  examSessionId: sessionId,
                  examQuizId: data.id,
                  submission:
                    data.quiz.__typename === "FreeResponseQuiz"
                      ? {
                          type: "FreeResponse",
                          body: freeResponseAnswer,
                          isDraft: false,
                        }
                      : {
                          type: "MultipleChoice",
                          index: chosenIndex,
                          isDraft: false,
                        },
                  startedAt: startedAt.toISOString(),
                },
              },
              updater: (store, mutationData) => {
                if (
                  mutationData === null ||
                  mutationData === undefined ||
                  mutationData.submitExamQuiz.quizSubmission === null ||
                  mutationData.submitExamQuiz.quizSubmission === undefined ||
                  mutationData.submitExamQuiz.userErrors.length > 0
                ) {
                  return;
                }

                const examSessionRecord = store.get(sessionId);

                if (!examSessionRecord) {
                  return;
                }

                const submissions =
                  examSessionRecord.getLinkedRecords("submissions");

                if (!submissions) {
                  return;
                }

                const submissionRecord = submissions.find(
                  (s) => s.getValue("examQuizId") === data.id,
                );

                if (submissionRecord) {
                  // Submission already updated by Relay
                  return;
                }

                const newSubmissionRecord = store.get(
                  mutationData.submitExamQuiz.quizSubmission.id,
                )!;

                examSessionRecord.setLinkedRecords(
                  [...submissions, newSubmissionRecord],
                  "submissions",
                );
              },
              onCompleted: () => {
                if (onSubmit) {
                  onSubmit();
                }
              },
            });
          }}
        >
          Yanıtla
          <PaperPlaneIcon className="ml-2 size-4" />
        </Button>
      </div>
    </>
  );
};

export default ExamQuiz;
