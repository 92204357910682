/**
 * @generated SignedSource<<a102404e7ff705c1ad8081b0f430623a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizSubmissionStatus = "DRAFT" | "GRADED" | "GRADE_PENDING" | "SKIPPED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type useFinishedExamQuizzesWithSubmissions_examSession$data = {
  readonly examQuizzes: ReadonlyArray<{
    readonly id: string;
    readonly points: number;
    readonly " $fragmentSpreads": FragmentRefs<"FinishedExamQuiz">;
  }>;
  readonly submissions: ReadonlyArray<{
    readonly examQuizId: string | null | undefined;
    readonly grade: number | null | undefined;
    readonly id: string;
    readonly status: QuizSubmissionStatus;
    readonly " $fragmentSpreads": FragmentRefs<"FinishedExamQuiz_submission">;
  }>;
  readonly " $fragmentType": "useFinishedExamQuizzesWithSubmissions_examSession";
};
export type useFinishedExamQuizzesWithSubmissions_examSession$key = {
  readonly " $data"?: useFinishedExamQuizzesWithSubmissions_examSession$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFinishedExamQuizzesWithSubmissions_examSession">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFinishedExamQuizzesWithSubmissions_examSession",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamQuiz",
      "kind": "LinkedField",
      "name": "examQuizzes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinishedExamQuiz"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudentQuizSubmission",
      "kind": "LinkedField",
      "name": "submissions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "examQuizId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinishedExamQuiz_submission"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamSession",
  "abstractKey": null
};
})();

(node as any).hash = "3cfd0b604159d53e416fda42589dcf3f";

export default node;
