"use client";

import { graphql, useFragment } from "react-relay";
import { QuizCourseItem$key } from "@generated/QuizCourseItem.graphql";
import { useCallback, useState } from "react";
import { WrenchIcon } from "lucide-react";
import SubmittedQuizCourseItem from "./SubmittedQuizCourseItem";
import AnswerQuizCourseItem from "./AnswerQuizCourseItem";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import CourseItemAttachmentsSection from "@/courses/components/CourseItemAttachmentsSection";
import { cn } from "@/lib/utils";
import CourseItemDescription from "@/courses/components/CourseItemDescription";

const QuizCourseItem: React.FC<{
  item: QuizCourseItem$key;
  mode: "store" | "learn";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}> = ({ item, mode, courseContentsSide }) => {
  const data = useFragment(
    graphql`
      fragment QuizCourseItem on QuizCourseItem {
        id
        title
        description
        quiz {
          id
        }
        viewerLatestSubmission {
          ...SubmittedQuizCourseItem_submission
        }
        ...SubmittedQuizCourseItem
        ...AnswerQuizCourseItem
        ...CourseItemAttachmentsSection_attachments
      }
    `,
    item,
  );

  const [retake, setRetake] = useState<boolean>(false);
  const onRetake = useCallback(() => setRetake(true), []);
  const onSubmit = useCallback(() => setRetake(false), []);

  if (!data.quiz) {
    return (
      <>
        {mode === "store" ? (
          <div className={cn("flex items-center gap-x-2")}>
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
          </div>
        ) : null}
        <div
          className={cn("px-4 pt-4 text-center sm:pt-8", {
            "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <WrenchIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Bu soru henüz yayınlanmadı.
          </h3>
          <p className="mt-1 text-sm text-foreground/60">
            Lütfen eğitmeninizle iletişime geçin.
          </p>
        </div>
        {mode === "learn" ? (
          <>
            <div
              className={cn("flex items-center gap-x-2 px-4 pt-4", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <h2 className="flex-1 truncate text-lg font-bold text-foreground">
                {data.title}
              </h2>
              <NextItemButton />
            </div>
            <div
              className={cn("flex items-center gap-x-2 px-4 pt-2", {
                "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
              })}
            >
              <ReportItemButton />
            </div>
          </>
        ) : null}
        <CourseItemDescription
          description={data.description}
          courseContentsSide={courseContentsSide}
        />
        <CourseItemAttachmentsSection
          attachments={data}
          courseContentsSide={courseContentsSide}
        />
      </>
    );
  }

  if (!data.viewerLatestSubmission || retake) {
    return <AnswerQuizCourseItem item={data} onSubmit={onSubmit} mode={mode} />;
  }

  return (
    <SubmittedQuizCourseItem
      item={data}
      submission={data.viewerLatestSubmission}
      onRetake={onRetake}
      mode={mode}
      courseContentsSide={courseContentsSide}
    />
  );
};

export default QuizCourseItem;
