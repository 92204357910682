/**
 * @generated SignedSource<<ca46fc769059d4992181379f05f2e49a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type ExamSessionStatus = "FAILED" | "GRADE_PENDING" | "IN_PROGRESS" | "PASSED" | "PAUSED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type CurrentExamSession_item$data = {
  readonly allowBack: boolean;
  readonly allowSkip: boolean;
  readonly id: string;
  readonly timeLimitSeconds: number | null | undefined;
  readonly title: string;
  readonly viewerCurrentSession: {
    readonly currentExamQuizIndex: number | null | undefined;
    readonly id: string;
    readonly startedAt: string;
    readonly status: ExamSessionStatus;
    readonly " $fragmentSpreads": FragmentRefs<"useExamQuizzesWithSubmissions_examSession">;
  };
  readonly " $fragmentType": "CurrentExamSession_item";
};
export type CurrentExamSession_item$key = {
  readonly " $data"?: CurrentExamSession_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentExamSession_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentExamSession_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ExamSession",
        "kind": "LinkedField",
        "name": "viewerCurrentSession",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentExamQuizIndex",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useExamQuizzesWithSubmissions_examSession"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewerCurrentSession"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSkip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowBack",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeLimitSeconds",
      "storageKey": null
    }
  ],
  "type": "ExamCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "d1e0013b5057d212dcc398da9508000a";

export default node;
