/**
 * @generated SignedSource<<daab8759b689ae4d40eda386d094d7c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ExamSessionStatus = "FAILED" | "GRADE_PENDING" | "IN_PROGRESS" | "PASSED" | "PAUSED" | "%future added value";
export type StartExamSessionInput = {
  examId: string;
  itemId?: string | null | undefined;
};
export type useStartExamSessionMutation$variables = {
  input: StartExamSessionInput;
};
export type useStartExamSessionMutation$data = {
  readonly startExamSession: {
    readonly examSession: {
      readonly currentExamQuizIndex: number | null | undefined;
      readonly id: string;
      readonly item: {
        readonly id: string;
        readonly viewerCanStartSession: boolean;
      } | null | undefined;
      readonly startedAt: string;
      readonly status: ExamSessionStatus;
      readonly " $fragmentSpreads": FragmentRefs<"useExamQuizzesWithSubmissions_examSession">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useStartExamSessionMutation = {
  response: useStartExamSessionMutation$data;
  variables: useStartExamSessionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentExamQuizIndex",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamCourseItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanStartSession",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStartExamSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartExamSessionPayload",
        "kind": "LinkedField",
        "name": "startExamSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamSession",
            "kind": "LinkedField",
            "name": "examSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useExamQuizzesWithSubmissions_examSession"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStartExamSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartExamSessionPayload",
        "kind": "LinkedField",
        "name": "startExamSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamSession",
            "kind": "LinkedField",
            "name": "examSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamQuiz",
                "kind": "LinkedField",
                "name": "examQuizzes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "quiz",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "QuizOption",
                            "kind": "LinkedField",
                            "name": "options",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "content",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isCorrect",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MultipleChoiceQuiz",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentQuizSubmission",
                "kind": "LinkedField",
                "name": "submissions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "examQuizId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selectedChoiceIndex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "freeResponseAnswer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secondsSpent",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submittedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "699a956dc5bd3d79b8b3b86d5eaf7900",
    "id": null,
    "metadata": {},
    "name": "useStartExamSessionMutation",
    "operationKind": "mutation",
    "text": "mutation useStartExamSessionMutation(\n  $input: StartExamSessionInput!\n) {\n  startExamSession(input: $input) {\n    examSession {\n      id\n      status\n      startedAt\n      currentExamQuizIndex\n      item {\n        id\n        viewerCanStartSession\n      }\n      ...useExamQuizzesWithSubmissions_examSession\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment ExamQuiz on ExamQuiz {\n  id\n  quiz {\n    id\n    question\n    title\n    __typename\n    ... on MultipleChoiceQuiz {\n      options {\n        content\n        description\n        isCorrect\n      }\n    }\n  }\n}\n\nfragment ExamQuiz_submission on StudentQuizSubmission {\n  id\n  selectedChoiceIndex\n  freeResponseAnswer\n  secondsSpent\n  startedAt\n  submittedAt\n  status\n}\n\nfragment useExamQuizzesWithSubmissions_examSession on ExamSession {\n  examQuizzes {\n    id\n    ...ExamQuiz\n  }\n  submissions {\n    id\n    examQuizId\n    status\n    ...ExamQuiz_submission\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e03a1014a8dc7e0e67e22a3ab4b70d6";

export default node;
