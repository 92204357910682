/**
 * @generated SignedSource<<340f6461b4a52c2527f593b3686308a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentSubmissionStatus = "DRAFT" | "GRADED" | "GRADE_PENDING" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type SubmittedAssignmentCourseItem_submission$data = {
  readonly assignmentStatus: AssignmentSubmissionStatus;
  readonly attachments: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AssignmentCourseItemAttachment_attachment">;
  }>;
  readonly freeResponseAnswer: any | null | undefined;
  readonly grade: number | null | undefined;
  readonly gradeFeedback: any | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "SubmittedAssignmentCourseItem_submission";
};
export type SubmittedAssignmentCourseItem_submission$key = {
  readonly " $data"?: SubmittedAssignmentCourseItem_submission$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssignmentCourseItem_submission">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmittedAssignmentCourseItem_submission",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeResponseAnswer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gradeFeedback",
      "storageKey": null
    },
    {
      "alias": "assignmentStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignmentCourseItemAttachment_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssignmentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "cf9b6c584ce15d02243fcc54842f423e";

export default node;
