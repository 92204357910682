/**
 * @generated SignedSource<<3dd907daff561c904d3c1ffe47a4d182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type EmbedCourseItem$data = {
  readonly customHtml: string | null | undefined;
  readonly description: any | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly url: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments">;
  readonly " $fragmentType": "EmbedCourseItem";
};
export type EmbedCourseItem$key = {
  readonly " $data"?: EmbedCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmbedCourseItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmbedCourseItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customHtml",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    }
  ],
  "type": "EmbedCourseItem",
  "abstractKey": null
};

(node as any).hash = "f6540cef87772cf0fd111cab54afee20";

export default node;
