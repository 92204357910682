"use client";

import { AssignmentCourseItemAttachment_attachment$key } from "@generated/AssignmentCourseItemAttachment_attachment.graphql";
import { graphql, useFragment, useMutation } from "react-relay";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import humanFileSize from "@/components/utils/humanFileSize";
import React, { useCallback, useState } from "react";
import { cn } from "@/lib/utils";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DownloadIcon, TrashIcon } from "@radix-ui/react-icons";
import DeleteAssignmentCourseItemAttachmentDialogContent from "./DeleteAssignmentCourseItemAttachmentDialogContent";
import { AssignmentCourseItemAttachmentGetAttachmentUrlMutation } from "@generated/AssignmentCourseItemAttachmentGetAttachmentUrlMutation.graphql";
import UpdateAttachmentDialog from "@/admin/courses/contents/components/UpdateAttachmentDialog";

interface Props {
  submissionId: string;
  attachment: AssignmentCourseItemAttachment_attachment$key;
}

const AssignmentCourseItemAttachment: React.FC<Props> = ({
  submissionId,
  attachment,
}) => {
  const data = useFragment(
    graphql`
      fragment AssignmentCourseItemAttachment_attachment on Attachment {
        id
        title
        createdAt
        fileName
        fileSize
        fileType
        ...DeleteAssignmentCourseItemAttachmentDialogContent_attachment
        ...UpdateAttachmentDialog_attachment
      }
    `,
    attachment,
  );

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const onAttachmentDeleted = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          {data.title} ({humanFileSize(data.fileSize)})
          <AttachmentDownloadButton
            assignmentSubmissionId={submissionId}
            attachmentId={data.id}
          />
          <>
            <UpdateAttachmentDialog attachment={data} />
            <Dialog
              open={deleteDialogOpen}
              onOpenChange={setDeleteDialogOpen}
              modal
            >
              <DialogTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={(e) => {
                    // Prevent the accordion from opening/closing
                    e.stopPropagation();
                  }}
                  asChild
                >
                  <span>
                    <span className="sr-only">Delete</span>
                    <TrashIcon />
                  </span>
                </Button>
              </DialogTrigger>
              <DeleteAssignmentCourseItemAttachmentDialogContent
                submissionId={submissionId}
                attachment={data}
                onAttachmentDeleted={onAttachmentDeleted}
              />
            </Dialog>
          </>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

const AttachmentDownloadButton: React.FC<{
  assignmentSubmissionId: string;
  attachmentId: string;
}> = ({ assignmentSubmissionId, attachmentId }) => {
  const [commitGetAttachmentDownloadUrl, isGetAttachmentDownloadUrlInFlight] =
    useMutation<AssignmentCourseItemAttachmentGetAttachmentUrlMutation>(graphql`
      mutation AssignmentCourseItemAttachmentGetAttachmentUrlMutation(
        $input: GetAttachmentUrlInput!
      ) {
        getAttachmentUrl(input: $input) {
          url
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  return (
    <Button
      variant="ghost"
      size="sm"
      type="button"
      loading={isGetAttachmentDownloadUrlInFlight}
      onClick={(e) => {
        // Prevent the accordion from opening/closing
        e.stopPropagation();

        if (isGetAttachmentDownloadUrlInFlight) {
          return;
        }

        commitGetAttachmentDownloadUrl({
          variables: {
            input: {
              attachmentId,
              assignmentSubmissionId,
            },
          },
          onCompleted: (data) => {
            if (data.getAttachmentUrl.userErrors.length > 0) {
              console.error(data.getAttachmentUrl.userErrors);
              return;
            }

            if (data.getAttachmentUrl.url)
              window.open(data.getAttachmentUrl.url, "_blank");
          },
          onError: (err) => {
            console.error(err);
          },
        });
      }}
    >
      <span
        className={cn({
          hidden: isGetAttachmentDownloadUrlInFlight,
        })}
      >
        <span className="sr-only">Download</span>
        <DownloadIcon />
      </span>
    </Button>
  );
};

export default AssignmentCourseItemAttachment;
