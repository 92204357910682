/**
 * @generated SignedSource<<f67f22c8bf0c446bbc9f344909e92de7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type FinishExamSessionInput = {
  examSessionId: string;
};
export type useFinishExamSessionMutation$variables = {
  input: FinishExamSessionInput;
};
export type useFinishExamSessionMutation$data = {
  readonly finishExamSession: {
    readonly examSession: {
      readonly id: string;
      readonly item: {
        readonly id: string;
        readonly viewerCanStartSession: boolean;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"FinishedExamSessionResults_session">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useFinishExamSessionMutation = {
  response: useFinishExamSessionMutation$data;
  variables: useFinishExamSessionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamCourseItem",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanStartSession",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuizOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCorrect",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceQuiz",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sampleAnswer",
      "storageKey": null
    }
  ],
  "type": "FreeResponseQuiz",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFinishExamSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FinishExamSessionPayload",
        "kind": "LinkedField",
        "name": "finishExamSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamSession",
            "kind": "LinkedField",
            "name": "examSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FinishedExamSessionResults_session"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFinishExamSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FinishExamSessionPayload",
        "kind": "LinkedField",
        "name": "finishExamSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamSession",
            "kind": "LinkedField",
            "name": "examSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finishedAt",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxPoints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamQuiz",
                "kind": "LinkedField",
                "name": "examQuizzes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "quiz",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Video",
                        "kind": "LinkedField",
                        "name": "solutionVideo",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "source",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "chaptersVttUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "posterUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailsUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InVideoQuiz",
                            "kind": "LinkedField",
                            "name": "inVideoQuizzes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "allowSkip",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showAtProgress",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "quiz",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v2/*: any*/),
                                  (v8/*: any*/),
                                  (v7/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "solutionVideoCookiesUrl",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentQuizSubmission",
                "kind": "LinkedField",
                "name": "submissions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "examQuizId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selectedChoiceIndex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "freeResponseAnswer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secondsSpent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submittedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "296c420354a0db0427025eb8f1e5969a",
    "id": null,
    "metadata": {},
    "name": "useFinishExamSessionMutation",
    "operationKind": "mutation",
    "text": "mutation useFinishExamSessionMutation(\n  $input: FinishExamSessionInput!\n) {\n  finishExamSession(input: $input) {\n    examSession {\n      id\n      item {\n        id\n        viewerCanStartSession\n      }\n      ...FinishedExamSessionResults_session\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment CourseItemVideoPlayer_video on Video {\n  id\n  duration\n  url\n  source\n  externalUrl\n  chaptersVttUrl\n  posterUrl\n  thumbnailsUrl\n  inVideoQuizzes {\n    id\n    allowSkip\n    showAtProgress\n    quiz {\n      __typename\n      id\n      title\n      question\n      ... on MultipleChoiceQuiz {\n        options {\n          content\n          description\n          isCorrect\n        }\n      }\n      ... on FreeResponseQuiz {\n        sampleAnswer\n      }\n    }\n  }\n}\n\nfragment FinishedExamQuiz on ExamQuiz {\n  id\n  points\n  quiz {\n    id\n    question\n    title\n    __typename\n    solutionVideo {\n      ...CourseItemVideoPlayer_video\n      id\n    }\n    solutionVideoCookiesUrl\n    ... on MultipleChoiceQuiz {\n      options {\n        content\n        description\n        isCorrect\n      }\n    }\n    ... on FreeResponseQuiz {\n      sampleAnswer\n    }\n  }\n}\n\nfragment FinishedExamQuiz_submission on StudentQuizSubmission {\n  id\n  selectedChoiceIndex\n  freeResponseAnswer\n  secondsSpent\n  startedAt\n  submittedAt\n  status\n  grade\n}\n\nfragment FinishedExamSessionResults_session on ExamSession {\n  id\n  status\n  finishedAt\n  points\n  maxPoints\n  ...useFinishedExamQuizzesWithSubmissions_examSession\n}\n\nfragment useFinishedExamQuizzesWithSubmissions_examSession on ExamSession {\n  examQuizzes {\n    id\n    points\n    ...FinishedExamQuiz\n  }\n  submissions {\n    id\n    examQuizId\n    grade\n    status\n    ...FinishedExamQuiz_submission\n  }\n}\n"
  }
};
})();

(node as any).hash = "978800ca0f0262c1e0855f6e44bb811d";

export default node;
