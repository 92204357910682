import { Button } from "@/components/ui/button";
import { LightbulbIcon } from "lucide-react";

interface Props {
  numberOfHints: number;
  numberOfShownHints: number;
  onClick: () => void;
}

const ShowHintButton: React.FC<Props> = ({
  numberOfHints,
  numberOfShownHints,
  onClick,
}) => {
  if (numberOfHints === 0) {
    return null;
  }

  return (
    <Button
      variant="outline"
      onClick={onClick}
      disabled={numberOfShownHints >= numberOfHints}
    >
      İpucu<span className="hidden whitespace-pre sm:inline"> Göster</span>
      <LightbulbIcon className="ml-2 h-4 w-4" />
    </Button>
  );
};

export default ShowHintButton;
