"use client";

import { graphql, useFragment } from "react-relay";
import { Button } from "@/components/ui/button";
import dynamic from "next/dynamic";
import { StartExamSession_item$key } from "@generated/StartExamSession_item.graphql";
import { LapTimerIcon } from "@radix-ui/react-icons";
import useStartExamSession from "@/courses/exam/hooks/useStartExamSession";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

interface Props {
  item: StartExamSession_item$key;
}

const StartExamSession: React.FC<Props> = ({ item }) => {
  const data = useFragment(
    graphql`
      fragment StartExamSession_item on ExamCourseItem {
        id
        title
        allowSkip
        allowBack
        timeLimitSeconds
        passingGradePercentage
        instructions
        exam @required(action: THROW) {
          id
          quizzes {
            id
            __typename
          }
        }
      }
    `,
    item,
  );

  const instructionText =
    data.instructions
      ?.map((instruction: { children: any[] }) =>
        instruction.children.map((child: { text: any }) => child.text).join(""),
      )
      .join("\n") || "";

  const newInstructions =
    instructionText +
    "\n" +
    //(data.timeLimitSeconds
    //  ? "\n Sınav süresi: " + data.timeLimitSeconds + " saniyedir."
    //  : "") +
    "\n Sınavda " +
    data.exam.quizzes.length +
    " soru bulunmaktadır." +
    ("\n Sınavda önceki sorulara geri gitme hakkınız " +
      (data.allowBack ? "bulunmaktadır." : "bulunmamaktadır.")) +
    ("\n Sınavda soruları pas geçme hakkınız " +
      (data.allowSkip ? "bulunmaktadır." : " bulunmamaktadır."));

  const plateElements = newInstructions.split("\n").map((line) => ({
    type: "paragraph",
    children: [{ text: line }],
  }));

  const [startExamSession, isStartExamSessionInFlight] = useStartExamSession();
  return (
    <div>
      <PlateEditor value={plateElements} readOnly dense />
      <Button
        size="lg"
        className="mt-2"
        onClick={() => {
          if (isStartExamSessionInFlight) {
            return;
          }
          startExamSession({
            variables: {
              input: {
                examId: data.exam.id,
                itemId: data.id,
              },
            },
          });
        }}
      >
        Sınava Başla
        <LapTimerIcon className="ml-2 size-4" />
      </Button>
    </div>
  );
};

export default StartExamSession;
