/**
 * @generated SignedSource<<7ef6a3d32d1286f548f1835e62a2192a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ExamCourseItem$data = {
  readonly exam: {
    readonly id: string;
    readonly quizzes: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly id: string;
  readonly passingGradePercentage: number | null | undefined;
  readonly title: string;
  readonly viewerCanStartSession: boolean;
  readonly viewerCurrentSession: {
    readonly id: string;
  } | null | undefined;
  readonly viewerLatestFinishedSession: {
    readonly " $fragmentSpreads": FragmentRefs<"FinishedExamSessionResults_session">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments" | "CurrentExamSession_item" | "StartExamSession_item">;
  readonly " $fragmentType": "ExamCourseItem";
};
export type ExamCourseItem$key = {
  readonly " $data"?: ExamCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamCourseItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamCourseItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamSession",
      "kind": "LinkedField",
      "name": "viewerCurrentSession",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamSession",
      "kind": "LinkedField",
      "name": "viewerLatestFinishedSession",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FinishedExamSessionResults_session"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Exam",
      "kind": "LinkedField",
      "name": "exam",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamQuiz",
          "kind": "LinkedField",
          "name": "quizzes",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passingGradePercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanStartSession",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartExamSession_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentExamSession_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    }
  ],
  "type": "ExamCourseItem",
  "abstractKey": null
};
})();

(node as any).hash = "8a4001cdb3560cb08907cad594e1b008";

export default node;
