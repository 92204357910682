/**
 * @generated SignedSource<<ef3dd2683dfd0870430b8ff5f688628d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SubmitQuizInput = {
  courseId?: string | null | undefined;
  inVideoQuizId?: string | null | undefined;
  itemId?: string | null | undefined;
  quizId: string;
  startedAt: string;
  submission: Record<string, any>;
};
export type useSubmitQuizMutation$variables = {
  input: SubmitQuizInput;
};
export type useSubmitQuizMutation$data = {
  readonly submitQuiz: {
    readonly quizSubmission: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SubmittedQuizCourseItem_submission">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type useSubmitQuizMutation = {
  response: useSubmitQuizMutation$data;
  variables: useSubmitQuizMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubmitQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitQuizPayload",
        "kind": "LinkedField",
        "name": "submitQuiz",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuizSubmission",
            "kind": "LinkedField",
            "name": "quizSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubmittedQuizCourseItem_submission"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSubmitQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitQuizPayload",
        "kind": "LinkedField",
        "name": "submitQuiz",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentQuizSubmission",
            "kind": "LinkedField",
            "name": "quizSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selectedChoiceIndex",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeResponseAnswer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grade",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gradeFeedback",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondsSpent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1661cda58c6e20162a29d4ad4981bde",
    "id": null,
    "metadata": {},
    "name": "useSubmitQuizMutation",
    "operationKind": "mutation",
    "text": "mutation useSubmitQuizMutation(\n  $input: SubmitQuizInput!\n) {\n  submitQuiz(input: $input) {\n    quizSubmission {\n      id\n      ...SubmittedQuizCourseItem_submission\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment SubmittedQuizCourseItem_submission on StudentQuizSubmission {\n  id\n  selectedChoiceIndex\n  freeResponseAnswer\n  grade\n  gradeFeedback\n  secondsSpent\n  startedAt\n  submittedAt\n  status\n}\n"
  }
};
})();

(node as any).hash = "1adfeab58f938144d0e3a30ea8cd0610";

export default node;
