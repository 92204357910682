"use client";

import { graphql, useFragment } from "react-relay";
import React, { useState } from "react";
import dynamic from "next/dynamic";
import { Button } from "@/components/ui/button";
import { AnswerQuizCourseItem$key } from "@generated/AnswerQuizCourseItem.graphql";
import useSubmitQuiz from "@/courses/quiz/hooks/useSubmitQuiz";
import {
  useOrganization,
  useViewer,
} from "@/student/context/OrganizationContext";
import {
  InfoCircledIcon,
  PaperPlaneIcon,
  Pencil2Icon,
  ValueNoneIcon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import CourseItemVideoPlayer from "@/courses/components/CourseItemVideoPlayer";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Alert, AlertTitle } from "@/components/ui/alert";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

interface Props {
  item: AnswerQuizCourseItem$key;
  mode: "store" | "learn";
  onSubmit: () => void;
}

const AnswerQuizCourseItem: React.FC<Props> = ({
  item,
  onSubmit,
  mode: learningMode,
}) => {
  const organization = useOrganization();
  const viewer = useViewer();

  const data = useFragment(
    graphql`
      fragment AnswerQuizCourseItem on QuizCourseItem {
        id
        title
        quiz @required(action: THROW) {
          __typename
          id
          question
          title
          ... on MultipleChoiceQuiz {
            options {
              content
              description
              isCorrect
            }
          }
          ... on FreeResponseQuiz {
            sampleAnswer
          }
          solutionVideo {
            ...CourseItemVideoPlayer_video
          }
          solutionVideoCookiesUrl
        }
        allowSkip
      }
    `,
    item,
  );

  const [chosenIndex, setChosenIndex] = useState<number | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [mode, setMode] = useState<"question" | "solution">("question");

  const [freeResponseAnswer, setFreeResponseAnswer] = useState<any>(null);
  const [startedAt] = useState(() => new Date());

  const [commitSubmitQuiz, isInFlight] = useSubmitQuiz();

  const submittedOption =
    submitted && chosenIndex !== null
      ? data.quiz.options?.[chosenIndex]
      : undefined;
  const correctOption = data.quiz.options?.find((option) => option.isCorrect);

  return (
    <section className="mt-2 px-2 sm:mt-4 sm:px-4">
      <h3 className="text-lg font-medium leading-6 text-foreground">
        {data.title}
      </h3>
      {mode === "question" ? (
        <>
          <PlateEditor
            value={data.quiz.question}
            readOnly
            dense
            className="mx-auto text-center lg:mt-8"
          />
          <div className="grid grid-cols-2 gap-4 py-2 sm:grid-cols-4 lg:py-4">
            {data.quiz.__typename === "FreeResponseQuiz" ? (
              <div className="col-span-2 sm:col-span-4">
                <PlateEditor
                  initialValue={freeResponseAnswer}
                  onChange={setFreeResponseAnswer}
                  organizationId={organization.id}
                />
              </div>
            ) : (
              data.quiz.options?.map((option, index) => (
                <Button
                  size="none"
                  variant="outline"
                  loading={isInFlight && index === chosenIndex}
                  disabled={isInFlight && index !== chosenIndex}
                  key={index}
                  className={cn("col-span-2 sm:last:odd:col-start-2", {
                    "bg-red-200 hover:bg-red-200":
                      viewer === null && chosenIndex === index,
                    "bg-green-200 hover:bg-green-200":
                      viewer === null &&
                      chosenIndex !== null &&
                      option.isCorrect,
                  })}
                  onClick={() => {
                    if (isInFlight) {
                      return;
                    }

                    setChosenIndex(index);

                    if (!viewer) {
                      setSubmitted(true);
                      return;
                    }

                    commitSubmitQuiz({
                      variables: {
                        input: {
                          quizId: data.quiz.id,
                          itemId: data.id,
                          startedAt: startedAt.toISOString(),
                          submission: {
                            type: "MultipleChoice",
                            index: index,
                            isDraft: false,
                          },
                        },
                      },
                      onCompleted: () => {
                        setSubmitted(true);
                        onSubmit();
                      },
                    });
                  }}
                >
                  <PlateEditor
                    value={option.content}
                    readOnly
                    className="bg-transparent"
                    dense
                  />
                  {viewer === null && submitted && option.description ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <InfoCircledIcon />
                        </TooltipTrigger>
                        <TooltipContent variant="background" className="p-0">
                          <PlateEditor
                            value={option.description}
                            readOnly
                            containerClassName={cn("flex items-center")}
                          />
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : null}
                </Button>
              ))
            )}
          </div>
          {viewer === null && submitted && data.quiz.sampleAnswer ? (
            <>
              <h3 className="mt-4 font-semibold">Örnek Cevap</h3>
              <PlateEditor value={data.quiz.sampleAnswer} readOnly dense />
            </>
          ) : null}
          {viewer === null &&
          submitted &&
          ((submittedOption && submittedOption.description) ||
            (correctOption && correctOption.description)) ? (
            <Alert
              variant={
                submittedOption && submittedOption.isCorrect
                  ? "success"
                  : "info"
              }
            >
              <AlertTitle className="font-bold">
                {submittedOption && submittedOption.isCorrect
                  ? "Tebrikler! Doğru cevap."
                  : "Maalesef soruyu doğru cevaplayamadın. Aşağıda cevabın açıklamasını bulabilirsin."}
              </AlertTitle>
              <PlateEditor
                value={
                  submittedOption?.description ?? correctOption?.description
                }
                readOnly
                containerClassName={cn("flex items-center")}
              />
            </Alert>
          ) : null}
        </>
      ) : (
        <CourseItemVideoPlayer
          title={data.title}
          video={data.quiz.solutionVideo!}
          itemId={data.id}
          mode={learningMode}
        />
      )}
      <div className="flex items-center justify-center gap-2 p-2">
        {data.allowSkip && !submitted ? (
          <Button
            disabled={Boolean(freeResponseAnswer) && freeResponseAnswer.length}
            onClick={() => {
              if (isInFlight) {
                return;
              }

              setChosenIndex(-1);

              if (!viewer) {
                setSubmitted(true);
                return;
              }

              commitSubmitQuiz({
                variables: {
                  input: {
                    quizId: data.quiz.id,
                    itemId: data.id,
                    startedAt: startedAt.toISOString(),
                    submission: {
                      type: "Skip",
                    },
                  },
                },
                onCompleted: () => {
                  setSubmitted(true);
                  onSubmit();
                },
              });
            }}
            variant="outline"
          >
            Boş Bırak
            <ValueNoneIcon className="ml-2 size-4" />
          </Button>
        ) : null}
        {data.quiz.__typename === "FreeResponseQuiz" && !submitted ? (
          <Button
            disabled={
              data.quiz.__typename === "FreeResponseQuiz" && !freeResponseAnswer
            }
            loading={isInFlight}
            onClick={() => {
              if (isInFlight) {
                return;
              }

              if (!viewer) {
                setSubmitted(true);
                return;
              }

              commitSubmitQuiz({
                variables: {
                  input: {
                    quizId: data.quiz.id,
                    itemId: data.id,
                    startedAt: startedAt.toISOString(),
                    submission: {
                      type: "FreeResponse",
                      body: freeResponseAnswer,
                      isDraft: false,
                    },
                  },
                },
                onCompleted: () => {
                  setSubmitted(true);
                  onSubmit();
                },
              });
            }}
          >
            Yanıtla
            <PaperPlaneIcon className="ml-2 size-4" />
          </Button>
        ) : null}
        {submitted && data.quiz.solutionVideo ? (
          mode === "question" ? (
            <Button variant="outline" onClick={() => setMode("solution")}>
              <VideoIcon className="mr-2 size-4" />
              Çözümü İzle
            </Button>
          ) : (
            <Button variant="outline" onClick={() => setMode("question")}>
              <Pencil2Icon className="mr-2 size-4" />
              Soruya Dön
            </Button>
          )
        ) : null}
      </div>
    </section>
  );
};

export default AnswerQuizCourseItem;
