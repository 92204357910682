/**
 * @generated SignedSource<<ebc9027e0c40f374a1d832ec991577e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type QuestionCourseItem$data = {
  readonly answer: any | null | undefined;
  readonly description: any | null | undefined;
  readonly hints: ReadonlyArray<any>;
  readonly id: string;
  readonly question: any | null | undefined;
  readonly title: string;
  readonly video: {
    readonly " $fragmentSpreads": FragmentRefs<"CourseItemVideoPlayer_video">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItemAttachmentsSection_attachments" | "CourseItemVideoPlayer_progress">;
  readonly " $fragmentType": "QuestionCourseItem";
};
export type QuestionCourseItem$key = {
  readonly " $data"?: QuestionCourseItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionCourseItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionCourseItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemVideoPlayer_progress"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CourseItemVideoPlayer_video"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hints",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseItemAttachmentsSection_attachments"
    }
  ],
  "type": "QuestionCourseItem",
  "abstractKey": null
};

(node as any).hash = "3af6c1c194d653a1055aa01fee79c4df";

export default node;
