"use client";

import { useCurrentItem } from "@/courses/context/CourseContext";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { FlagIcon } from "lucide-react";

const ReportItemButton: React.FC = () => {
  const currentItem = useCurrentItem();

  if (!currentItem) return null;

  return (
    <Button variant="outline" asChild>
      <Link
        href={`mailto:support@pi.education?subject=Hata%20bildirimi&body=id:${currentItem.id}%0A%0ALütfen%20sorun%20hakkında%20bilgi%20verin`}
      >
        Sorun Bildir <FlagIcon className="ml-2 h-4 w-4" />
      </Link>
    </Button>
  );
};

export default ReportItemButton;
